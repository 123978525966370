<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            
            <form @submit.prevent="insert">

              <div class="row">
                <div class="col-lg-12 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Rute <span class="text-danger">*</span></label>
                    <model-select :options="needData3" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.id_routes" class="custom-width form-control" placeholder="" isDisabled="true"></model-select>
                    
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Jenis Muatan <span class="text-danger">*</span></label>
                    <model-select :options="needData1" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.id_jenis_muatan" class="custom-width form-control" placeholder="" isDisabled="true"></model-select>
                    
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Kategori Kendaraan <span class="text-danger">*</span></label>
                    <model-select :options="needData2" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.id_kategori_kendaraan" class="custom-width form-control" placeholder="" isDisabled="true"></model-select>
                    
                  </div>
                </div>
                <div class="col-lg-12 col-sm-6 col-12 mb-3">
                  <div class="row">

                    <table class="table table-responsive">
                      <thead>
                        <th>Additional</th>
                        <th>Status</th>
                        <th>Jumlah</th>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in additionalColumn" :key="index">
                          <td>{{ item.label }}</td>
                          <td>
                            <input class="form-check-input" type="checkbox" v-model="currentData[item.statusKey]" @change="toggleInputStatus(item.valueKey, currentData[item.statusKey])" :true-value="'AKTIF'" :false-value="'TIDAK'" :id="'checkbox-' + index">
                          </td>
                          <td>
                            <input type="text" class="form-control" v-model="currentData[item.valueKey]"  @input="validateAndFormatCurrency($event, item.valueKey)" :disabled="currentData[item.statusKey] !== 'AKTIF'" placeholder="" /> 
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>


                </div>
                
              

                

                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <router-link to="mappingkomponen" class="btn btn-cancel">Batal</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, rupiahInput, rupiahoutput, rupiah } from "@/assets/js/function";
import { ModelSelect } from 'vue-search-select';
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {
    ModelSelect,
  },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Perbaharui Mapping Komponen",
      title1: "perbaharui mapping komponen",
      config: "",
      api: "",

      //data
      currentData: {
          id_jenis_muatan: "", // Selected ID
          uang_jalan_status: 'TIDAK',
          uang_tinggal_status: 'TIDAK',
          uang_langsir_status: 'TIDAK',
          uang_tambahan_status: 'TIDAK',
          uang_jalan_tambahan_status: 'TIDAK',
          bonus_tonase_status: 'TIDAK',
          uang_bongkar_status: 'TIDAK',
          uang_makan_status: 'TIDAK',
          uang_premi_status: 'TIDAK',
          bonus_trip_status: 'TIDAK',
          upah_borongan_status: 'TIDAK',
          uang_jalan: '',
          uang_tinggal: '',
          uang_langsir: '',
          uang_tambahan: '',
          uang_jalan_tambahan: '',
          bonus_tonase: '',
          uang_bongkar: '',
          uang_makan: '',
          uang_premi: '',
          bonus_trip: '',
  
          // component: [],
      },
      


      additionalColumn: [
        { label: 'Uang Jalan', statusKey: 'uang_jalan_status', valueKey: 'uang_jalan' },
        { label: 'Uang Jalan Tambahan', statusKey: 'uang_jalan_tambahan_status', valueKey: 'uang_jalan_tambahan' },
        { label: 'Uang Tambahan', statusKey: 'uang_tambahan_status', valueKey: 'uang_tambahan' },
        { label: 'Uang Tinggal', statusKey: 'uang_tinggal_status', valueKey: 'uang_tinggal' },
        { label: 'Uang Langsir', statusKey: 'uang_langsir_status', valueKey: 'uang_langsir' },
        { label: 'Upah Borongan', statusKey: 'upah_borongan_status', valueKey: 'upah_borongan' },
        { label: 'Bonus Tonase', statusKey: 'bonus_tonase_status', valueKey: 'bonus_tonase' },
        { label: 'Uang Bongkar', statusKey: 'uang_bongkar_status', valueKey: 'uang_bongkar' },
        { label: 'Uang Makan', statusKey: 'uang_makan_status', valueKey: 'uang_makan' },
        { label: 'Uang premi', statusKey: 'uang_premi_status', valueKey: 'uang_premi' },
        { label: 'Bonus Trip', statusKey: 'bonus_trip_status', valueKey: 'bonus_trip' }
      ],

    

      needData1: [],
      needData2: [],
      needData3: [],

      

    };
  },

  

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    this.prepareData()
    // this.getDetailMapping()

  },
  
  methods: {
    formatCurrency(label, number) {
      this.currentData[label] = rupiahInput(number);
    },

    validateAndFormatCurrency(event, key) {
      let value = event.target.value;
      
      // Allow only numbers and prevent execution for strings
      // if (/^\d+$/.test(value.replace(/\D/g, ''))) {
        
      //   this.formatCurrency(key, value);
        
        
      // }


      if (value.startsWith('=')) {
        // Optionally, you can process or store the formula differently here.
        return;
      }

      // Otherwise, if input is only numbers, format as currency
      // Remove non-digit characters, then test if only digits remain
      if (/^\d+$/.test(value.replace(/\D/g, ''))) {
        this.formatCurrency(key, value);
      }


    },

    toggleInputStatus(valueKey, status) {
      // If status is 'TIDAK AKTIF', reset and disable the input
      if (status !== 'AKTIF') {
        this.currentData[valueKey] = ''; // Reset value when disabled
      }
    },

    

    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },

     // New method to loop through each currency field and format it
    formatAllCurrencyValues() {
      this.additionalColumn.forEach((item) => {

        const value = this.currentData[item.valueKey];

        if (typeof value === 'string' && value.trim().startsWith('=')) {
            this.currentData[item.valueKey] = value;
            return;
        }

        // Proceed with currency formatting if numeric
        // if (!isNaN(parseFloat(value))) {
        //     this.currentData[item.valueKey] = this.formatCurrency(item.valueKey, value);
        // }


        if (this.currentData[item.statusKey] === 'AKTIF' && value && !isNaN(parseFloat(value))) {
          // Check if the value is numeric (allowing strings that represent numbers)
          if (!isNaN(parseFloat(value))) {
            this.formatCurrency(item.valueKey, value);
          }
        }
      });
    },


    prepareData() {

      this.currentData = JSON.parse(localStorage.getItem('currentData'));

      
      // jenis muatan
      const needData1 = JSON.parse(localStorage.getItem('needData1')) || [];
      this.needData1 = needData1.map((item) => ({
        value: item.id,
        text: item.nama,
        
      }));

      // kategori kendaraan 
      const needData2 = JSON.parse(localStorage.getItem('needData2')) || [];
      this.needData2 = needData2.map((item) => ({
        value: item.id,
        text: item.nama,
        
      }));

      // rute
      const needData3 = JSON.parse(localStorage.getItem('needData3')) || [];
      this.needData3 = needData3.map((item) => ({
        value: item.id,
        text: item.nama,
        
      }));


      const indexJenisMuatan = needData1.findIndex((record) => record.value == this.currentData.id_jenis_muatan);
      if (indexJenisMuatan > -1) {
        this.currentData.id_jenis_muatan = this.needData1[indexJenisMuatan].value;
      }

      const indexKategoriKendaraan = needData2.findIndex((record) => record.value == this.currentData.id_kategori_kendaraan);
      if (indexKategoriKendaraan > -1) {
        this.currentData.id_kategori_kendaraan = this.needData2[indexKategoriKendaraan].value;
      }

      const indexRute = needData3.findIndex((record) => record.value == this.currentData.id_routes);
      if (indexRute > -1) {
        this.currentData.id_routes = this.needData3[indexRute].value;
      }

      this.additionalColumn.forEach((item) => {
          let value = this.currentData[item.valueKey];

          if (typeof value === 'string' && value.trim().startsWith('=')) {
              this.currentData[item.valueKey] = value; // Keep formula as is
          }
      });

      
      this.formatAllCurrencyValues();


      
      // Ensure checkboxes reflect saved data and reset inputs if needed
      // if (this.additionalColumn && this.currentData) {
      //     this.additionalColumn.forEach((item) => {
      //         if (!this.currentData[item.statusKey]) {
      //             this.currentData[item.statusKey] = "TIDAK"; // Default value if missing
      //         }

      //         // Reset valueKey if status is 'TIDAK AKTIF'
      //         if (this.currentData[item.statusKey] !== "AKTIF") {
      //             this.currentData[item.valueKey] = ""; // Reset value
      //         }
      //     });
      // }


    },




    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

    
      
      const fields = [
        "uang_jalan", "uang_tinggal", "uang_langsir", "uang_jalan_tambahan",
        "upah_borongan", "bonus_tonase", "uang_bongkar", "uang_tambahan", "uang_makan", "uang_premi", "bonus_trip"
      ];

      fields.forEach(field => {
        formData.delete(field);
        let value = this.currentData[field] || ''; // Ensure value is a string

        // Check if the value is numeric after removing non-digit characters
        

        if (typeof value === "string" && (value.trim().startsWith('=') || isNaN(parseFloat(value)))) {
            formData.append(field, value); // Append as is (no formatting)
        } else {
            formData.append(field, rupiahoutput(value)); // Format only if numeric
        }
    
      });
      


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'mapping_komponen_update';
      } else {
        this.api = base_url + 'mapping_komponen_update';
      }

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      // CloseLoading();
      // return false;

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("mappingkomponen");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>
