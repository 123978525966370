<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">

              <div class="row">
                <div class="col-lg-12 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor SO <span class="text-danger">*</span></label>
                    <model-select :options="needData1" placeholder="Pilih Projek" v-model="currentData.id_project"
                      style="z-index: 101;">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nama Perusahaan </label>
                    <input type="text" v-model="currentData.nama_customer" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Rute </label>
                    <input type="text" v-model="currentData.route" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Jenis Muatan</label>
                    <input type="text" v-model="currentData.jenis_muatan" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal SO</label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker class="picker" :editable="true" :clearable="false" v-model="currentData.so_date"
                        disabled />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor PO </label>
                    <input type="text" v-model="currentData.po_no" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor DO </label>
                    <input type="text" v-model="currentData.do_no" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor STO </label>
                    <input type="text" v-model="currentData.sto_no" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor SPK </label>
                    <input type="text" v-model="currentData.spk_no" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Kuantitas SO </label>
                    <input type="text" v-model="currentData.so_qty" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12 ">
                  <div class="form-group">
                    <label>Toleransi Susut </label>
                    <div class="position-relative w-100">
                      <input type="number" v-model="currentData.toleransi_susut" placeholder="" disabled />
                      <div class="position-absolute top-0 end-0 me-4 pe-2 h-100 d-flex align-items-center">%</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Lokasi Muat </label>
                    <input type="text" v-model="currentData.lokasi_muat" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Lokasi Bongkar </label>
                    <input type="text" v-model="currentData.lokasi_bongkar" placeholder=""/>
                  </div>
                </div>

                <div class="col-lg-12 col-sm-6 col-12 mb-5 mt-5"></div>



                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Jumlah Angkutan</label>
                    <div class="position-relative w-100">
                      <input type="number" v-model="currentData.qty" placeholder="" required />
                      <div class="position-absolute top-0 end-0 me-4 pe-2 h-100 d-flex align-items-center">kg</div>
                    </div>
                  </div>
                </div> -->
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>No. Pol <span class="text-danger">*</span></label>
                    <model-select :options="needData3" placeholder="Pilih Aset (No polisi)"
                      v-model="currentData.id_asset" style="z-index: 501;" > <!-- isDisabled="true"-->
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Supir <span class="text-danger">*</span></label>
                    <model-select :options="needData2" placeholder="Pilih Supir" v-model="currentData.id_driver" style="z-index: 502;">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Kategori Kendaraan</label>
                    <input type="text" v-model="currentData.jenis_aset" placeholder="" disabled />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nama Kendaraan</label>
                    <input type="text" v-model="currentData.kendaraan" placeholder="" disabled />
                  </div>
                </div>
                
                
                
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal Muat <span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker class="picker" :editable="true" :clearable="false"
                        v-model="currentData.tanggal_muat" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal Bongkar <span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 101;">
                      <datepicker class="picker" :editable="true" :clearable="false"
                        v-model="currentData.tanggal_bongkar" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>



                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Jenis Voucher <span class="text-danger">*</span></label>
                    <vue-select :options="VoucherList" v-model="currentData.jenis_voucher" placeholder="None"
                      required />
                  </div>
                </div>

                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Menggunakan Supervisor</label>
                    <input type="checkbox" name="checkbox" v-model="currentData.is_supervisor" />
                  </div>
                </div>

                <pageheader :title="currentData.jenis_voucher !== null ? currentData.jenis_voucher : 'NO-VOUCHER'"
                  title1="" v-if="currentData.jenis_voucher != null" />

                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher == 'VOUCHER'">
                  <div class="form-group">
                    <label>No Voucher</label>
                    <input type="text" v-model="currentData.no_voucher" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher == 'VOUCHER'">
                  <div class="form-group">
                    <label>Jenis BBM</label>
                    <vue-select :options="BbmList" v-model="currentData.jenis_bbm" placeholder="None" required />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher == 'VOUCHER'">
                  <div class="form-group">
                    <label>Permintaan dalam liter</label>
                    <input type="number" v-model="currentData.permintaan" placeholder="" />
                  </div>
                </div>


                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null">
                  <div class="form-group">
                    <label>Keterangan</label>
                    <input type="text" v-model="currentData.keterangan" placeholder="" />
                  </div>
                </div>

                

                
                <div class="col-lg-3 col-sm-6 col-12" v-show="currentData.jenis_voucher == 'VOUCHER'">
                  <div class="form-group">
                    <label>File Voucher <span class="text-danger">*</span></label>
                    <input type="file" class="form-control" ref="file" />
                  </div>
                </div>

                <div class="" v-if="currentData.jenis_voucher != null ">
                  <h5 class="mb-3 fw-bold">KOMPONEN</h5>
                  <label class="mb-3" v-if="this.currentData.id_mapping_komponen == '' || this.currentData.id_mapping_komponen == null || this.currentData.id_mapping_komponen == 'undefined'"><span class="text-danger">*</span> Komponen untuk trip ini belum terdaftar pada mapping komponen</label>
                  
                  <div class="row">

                    <div class="col-md-3 col-sm-6 col-12" 
                        v-if="currentData.jenis_voucher != null  && uang_jalan_status === 'AKTIF'">
                      <div class="form-group">
                        <label>Uang Jalan <span class="text-danger">*</span></label>
                        <input type="text" class="mb-1" v-model="currentData.uang_jalan" placeholder="Rp 0,-"
                          @input="validateAndFormatCurrency($event, 'uang_jalan')" />
                      </div>
                    </div>
  
                    <div class="col-md-3 col-sm-6 col-12" 
                        v-if="currentData.jenis_voucher != null  && uang_jalan_tambahan_status === 'AKTIF'">
                      <div class="form-group">
                        <label>Uang Jalan Tambahan<span class="text-danger">*</span></label>
                        <input type="text" class="mb-1" v-model="currentData.uang_jalan_tambahan" placeholder="Rp 0,-"
                          @input="validateAndFormatCurrency($event, 'uang_jalan_tambahan')" />
                      </div>
                    </div>
  
                    <div class="col-lg-3 col-sm-6 col-12" 
                        v-if="currentData.jenis_voucher != null && uang_tambahan_status === 'AKTIF'">
                      <div class="form-group">
                        <label>Uang Tambahan <span class="text-danger">*</span></label>
                        <input type="text" class="mb-1" v-model="currentData.uang_tambahan" placeholder="Rp 0,-"
                          @input="validateAndFormatCurrency($event, 'uang_tambahan')" />
                      </div>
                    </div>
  
                    <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null && uang_tinggal_status === 'AKTIF' ">
                      <div class="form-group">
                        <label>Uang Tinggal <span class="text-danger">*</span></label>
                        <input type="text" class="mb-1" v-model="currentData.uang_tinggal" placeholder="Rp 0,-"
                          @input="validateAndFormatCurrency($event, 'uang_tinggal')" />
                      </div>
                    </div>
  
                    <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null && uang_langsir_status === 'AKTIF'"><!-- && statusLangsir  -->
                      <div class="form-group">
                        <label>Uang Langsir <span class="text-danger">*</span></label>
                        <!-- <input type="text" class="mb-1" v-model="currentData.uang_langsir" placeholder="Rp 0,-"
                          @input="formatCurrency('uang_langsir', currentData.uang_langsir)" required /> -->
                        <input type="text" class="mb-1" v-model="currentData.uang_langsir" placeholder="Rp 0,-"
                          @input="validateAndFormatCurrency($event, 'uang_langsir')" />
                      </div>
                    </div>
  
                    <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null && uang_bongkar_status === 'AKTIF'">
                      <div class="form-group">
                        <label>Uang Bongkar <span class="text-danger">*</span></label>
                        <input type="text" class="mb-1" v-model="currentData.uang_bongkar" placeholder="Rp 0,-"
                          @input="validateAndFormatCurrency($event, 'uang_bongkar')" />
                      </div>
                    </div>
  
                    <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null && uang_premi_status === 'AKTIF'">
                      <div class="form-group">
                        <label>Uang Premi <span class="text-danger">*</span></label>
                        <input type="text" class="mb-1" v-model="currentData.uang_premi" placeholder="Rp 0,-"
                          @input="validateAndFormatCurrency($event, 'uang_premi')" />
                      </div>
                    </div>
  
                    <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null && uang_makan_status === 'AKTIF'">
                      <div class="form-group">
                        <label>Uang Makan <span class="text-danger">*</span></label>
                        <input type="text" class="mb-1" v-model="currentData.uang_makan" placeholder="Rp 0,-"
                          @input="validateAndFormatCurrency($event, 'uang_makan')" />
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null && upah_borongan_status === 'AKTIF'">
                      <div class="form-group">
                        <label>Upah Borongan <span class="text-danger">*</span></label>
                        <input type="text" class="mb-1" v-model="currentData.upah_borongan" placeholder="Rp 0,-"
                          @input="validateAndFormatCurrency($event, 'upah_borongan')" />
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null && bonus_tonase_status === 'AKTIF'">
                      <div class="form-group">
                        <label>Bonus Tonase  <span class="text-danger">*</span></label>
                        <input type="text" class="mb-1" v-model="currentData.bonus_tonase" placeholder="Rp 0,-"
                          @input="validateAndFormatCurrency($event, 'bonus_tonase')" />
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null && bonus_trip_status === 'AKTIF'">
                      <div class="form-group">
                        <label>Bonus Trip  <span class="text-danger">*</span></label>
                        <input type="text" class="mb-1" v-model="currentData.bonus_trip" placeholder="Rp 0,-"
                          @input="validateAndFormatCurrency($event, 'bonus_trip')" />
                      </div>
                    </div>
                  </div>
                </div>

                



                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <button type="button" @click="newTrip" class="btn btn-submit me-2">Trip Baru</button>
                  <router-link to="tripkhusus" class="btn btn-cancel">Batal</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.form-group input[type="number"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
}

.form-group input[type="number"]:focus {
  border-color: #043249;
  background: #fff;
}
</style>


<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, rupiahInput, rupiahoutput, moneyFormat } from "@/assets/js/function";
import { ModelSelect } from 'vue-search-select';
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {
    ModelSelect,
  },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Tambah Trip Khusus",
      title1: "atur Trip Khusus",
      config: "",
      api: "",

      //data
      currentData: {
        permintaan: "",
        uang_jalan: "",
        uang_jalan_tambahan: "",
        uang_tinggal: "",
        uang_langsir: "",
        uang_premi: "",
        bonus_tonase: "",
        bonus_trip: "",
        uang_tambahan: "",
        uang_borongan: "",
        uang_makan: "",
        upah_borongan: "",

        
        id_asset: "",
        id_driver: "",
        jenis_aset: "",
        nama_customer: "",
      },
      VoucherList: ["VOUCHER", "NON - VOUCHER"],
      BbmList: ["Pertamax Racing", "Pertamax Turbo", "Pertamax", "Pertalite", "Premium", "Pertamina Dex", "Dexlite", "Solar"],
      needData1: [],
      needData2: [],
      needData3: [],
      needData4: [],
      needData5: [],

      selectedIds: {
        id_jenis_muatan: null,
        id_routes: null,
        id_kategori_kendaraan: null,
        // id_driver: null,
      },
      
      uang_jalan_status: null,
      uang_jalan_tambahan_status: null,
      uang_tambahan_status: null,
      uang_tinggal_status: null,
      uang_langsir_status: null,
      uang_bongkar_status: null,
      uang_premi_status: null,
      uang_makan_status: null,
      upah_borongan_status: null,
      bonus_tonase_status: null,
      bonus_trip_status: null,


      
      


    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    this.prepareData();
    this.currentData.tanggal_muat = new Date();
    this.currentData.tanggal_bongkar = new Date();

  },
  
  watch: {
    "currentData.id_project": {
      handler(newValue) {
        const index = this.needData1.findIndex((record) => record.value === newValue);
        // console.log(index)
        if (index > -1) {
          this.currentData.lokasi_muat = this.needData1[index].lokasi_muat;
          this.currentData.lokasi_bongkar = this.needData1[index].lokasi_bongkar;
          this.currentData.jenis_muatan = this.needData1[index].jenis_muatan;
          this.currentData.route = this.needData1[index].route;
          this.currentData.nama_customer = this.needData1[index].nama_customer;
          // this.currentData.uang_jalan = rupiahInput(this.needData1[index].uang_jalan);
          // this.currentData.uang_langsir = rupiahInput(this.needData1[index].uang_langsir);
          // this.currentData.langsir = rupiahInput(this.needData1[index].langsir);
          // this.currentData.uang_tinggal = rupiahInput(this.needData1[index].uang_tinggal);
          // this.currentData.bonus = rupiahInput(this.needData1[index].uang_tambahan);
          // this.currentData.tambahan = rupiahInput(this.needData1[index].tambahan);
          this.currentData.sto_no = this.needData1[index].sto_no;
          this.currentData.do_no = this.needData1[index].do_no;
          this.currentData.spk_no = this.needData1[index].spk_no;
          this.currentData.po_no = this.needData1[index].po_no;
          this.currentData.toleransi_susut = this.needData1[index].toleransi_susut;
          this.currentData.so_qty = moneyFormat(this.needData1[index].so_qty);
          this.currentData.so_date = new Date(this.needData1[index].so_date);
          // this.currentData.uang_jalan = rupiahInput(this.needData1[index].uang_jalan);
          // this.currentData.uang_tinggal = rupiahInput(this.needData1[index].uang_tinggal);
          // this.currentData.uang_langsir = rupiahInput(this.needData1[index].uang_langsir);
          // this.currentData.uang_tambahan = rupiahInput(this.needData1[index].uang_tambahan);
          // this.currentData.id_jenis_muatan = this.needData1[index].id_jenis_muatan;
          // this.currentData.id_routes = this.needData1[index].id_routes;
          
          console.log('cek id jenis muatan :', this.needData1[index].id_jenis_muatan)
          console.log('cek id rute :', this.needData1[index].id_routes)

          // for following additional
          // Save IDs to selectedIds
          this.selectedIds.id_jenis_muatan = this.needData1[index].id_jenis_muatan;
          this.selectedIds.id_routes = this.needData1[index].id_routes;
          


        }

      }
    },
    

    "currentData.id_asset": {
      handler(newValue) {
        // Find the index of the asset in needData3
        const assetIndex = this.needData3.findIndex((record) => record.value === newValue);
        if (assetIndex > -1) {
          this.currentData.jenis_aset = this.needData3[assetIndex].jenis;
          this.currentData.kendaraan = this.needData3[assetIndex].nama_asset;
          // this.currentData.id_kategori_kendaraan = this.needData3[assetIndex].id_kategori_kendaraan;

           // Save ID to selectedIds
          this.selectedIds.id_kategori_kendaraan = this.needData3[assetIndex].id_kategori_kendaraan;

          console.log('cek id kategori dari no.pol', this.selectedIds.id_kategori_kendaraan)

          // Find the driver that matches the selected asset
          const driverIndex = this.needData2.findIndex((record) => record.id_asset === newValue);
          if (driverIndex > -1) {
            this.currentData.id_driver = this.needData2[driverIndex].value;
          }
        }
      }
    },
    
    "currentData.id_driver": {
      handler(newValue) {
        // Find the index of the driver in needData2
        const driverIndex = this.needData2.findIndex((record) => record.value === newValue);
        if (driverIndex > -1) {
          // this.currentData.id_kategori_kendaraan = this.needData2[driverIndex].id_kategori_kendaraan;
          // this.currentData.id_asset = this.needData2[driverIndex].id_asset;
          // console.log('cek id kategori dari driver', this.currentData.id_kategori_kendaraan)

          // // Find the asset that matches the selected driver
          // const assetIndex = this.needData3.findIndex((record) => record.value === this.needData2[driverIndex].id_asset);
          // if (assetIndex > -1) {
          //   this.currentData.kendaraan = this.needData3[assetIndex].nama_asset;
          //   this.currentData.jenis_aset = this.needData3[assetIndex].jenis;
          // }
        }
      }
    },

    "currentData.jenis_voucher": {
      handler(newValue) {
        if (newValue) {
          const selected = this.needData5.find(
            (record) =>
              record.id_routes === this.selectedIds.id_routes &&
              record.id_jenis_muatan === this.selectedIds.id_jenis_muatan &&
              record.id_kategori_kendaraan === this.selectedIds.id_kategori_kendaraan
          );


          if (selected) {
            console.log('Data ditemukan:', selected);

            if (newValue != null) {
              console.log('Jenis Voucher adalah NON - VOUCHER');

              this.uang_jalan_status = selected.uang_jalan_status; // Example: 'AKTIF' or 'NONAKTIF'
              this.uang_jalan_tambahan_status = selected.uang_jalan_tambahan_status;
              this.uang_tambahan_status = selected.uang_tambahan_status;
              this.uang_tinggal_status = selected.uang_tinggal_status;
              this.uang_langsir_status = selected.uang_langsir_status;
              this.uang_bongkar_status = selected.uang_bongkar_status;
              this.uang_premi_status = selected.uang_premi_status;
              this.uang_makan_status = selected.uang_makan_status;
              this.upah_borongan_status = selected.upah_borongan_status;
              this.bonus_tonase_status = selected.bonus_tonase_status;
              this.bonus_trip_status = selected.bonus_trip_status;

              // the value
              this.currentData.uang_jalan = this.validationRupiah(selected.uang_jalan); // Example: 'AKTIF' or 'NONAKTIF'
              this.currentData.uang_jalan_tambahan = this.validationRupiah(selected.uang_jalan_tambahan);
              this.currentData.uang_tambahan = this.validationRupiah(selected.uang_tambahan);
              this.currentData.uang_tinggal = this.validationRupiah(selected.uang_tinggal);
              this.currentData.uang_langsir = this.validationRupiah(selected.uang_langsir);
              this.currentData.uang_bongkar = this.validationRupiah(selected.uang_bongkar);
              this.currentData.uang_premi = this.validationRupiah(selected.uang_premi);
              this.currentData.uang_makan = this.validationRupiah(selected.uang_makan);
              this.currentData.upah_borongan = this.validationRupiah(selected.upah_borongan);
              this.currentData.bonus_tonase = this.validationRupiah(selected.bonus_tonase);
              this.currentData.bonus_trip = this.validationRupiah(selected.bonus_trip);

              this.currentData.id_mapping_komponen = selected.value;

              // console.log(this.currentData.id_mapping_komponen)
              // Perform any additional logic when the voucher is "NON - VOUCHER"
            }
          }else {
            // console.log('data engga ada')
            this.uang_jalan_status = null;
            this.uang_tambahan_status = null;
            this.uang_jalan_tambahan_status = null;
            this.uang_tinggal_status = null;
            this.uang_langsir_status = null;
            this.uang_bongkar_status = null;
            this.uang_premi_status = null;
            this.uang_makan_status = null;
            this.upah_borongan_status = null;
            this.bonus_tonase_status = null;
            this.bonus_trip_status = null;

            this.currentData.id_mapping_komponen = '';

          }
        }
      },
      
    },


  },
  
  methods: {

    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    formatCurrency(label, number) {
      this.currentData[label] = rupiahInput(number);
    },
    
    validateAndFormatCurrency(event, key) {
      let value = event.target.value;
      
      // If value starts with "=" (Excel formula), do nothing.
      if (value.startsWith('=')) {
        return;
      }
      
      // If the input is numeric (after stripping non-digits), then format it.
      if (/^\d+$/.test(value.replace(/\D/g, ''))) {
        this.formatCurrency(key, value);
      }
    },

    validationRupiah(value) {
      // If the value is a string that starts with "=" (Excel formula), return it as is.
      if (typeof value === 'string' && value.trim().startsWith('=')) {
        return value;
      }
      // If the value is numeric (either a number or numeric string), format it.
      if (!isNaN(Number(value))) {
        return rupiahInput(value);
      }
      // Otherwise, return the value unmodified.
      return value;
    },

    
    prepareData() {
      // needData1
      const needData1 = JSON.parse(localStorage.getItem('needData1'));
      // for filter
      const filterData1 = needData1.filter(item => item.jenis === 'KHUSUS');
      filterData1.forEach((item, index) => {
        const element = {
          value: item.id, text: item.nama,
          id_jenis_muatan: item.id_jenis_muatan,
          id_routes: item.id_routes,
          lokasi_muat: item.lokasi_muat,
          lokasi_bongkar: item.lokasi_bongkar,
          jenis_muatan: item.jenis_muatan,
          route: item.route,
          // uang_jalan: item.uang_jalan,
          // uang_langsir: item.uang_langsir,
          // langsir: item.langsir,
          // uang_tinggal: item.uang_tinggal,
          // uang_tambahan: item.uang_tambahan,
          nama_customer: item.nama_customer,
          sto_no: item.sto_no,
          do_no: item.do_no,
          spk_no: item.spk_no,
          po_no: item.po_no,
          toleransi_susut: item.toleransi_susut,
          so_qty: item.so_qty,
          so_date: item.so_date,
          // uang_jalan: item.uang_jalan,
          // uang_tinggal: item.uang_tinggal,
          // uang_langsir: item.uang_langsir,
          // uang_tambahan: item.uang_tambahan,
          // uang_borongan: item.uang_borongan,
        }


        this.needData1.push(element);
      });

      // needData2
      const needData2 = JSON.parse(localStorage.getItem('needData2'));
      needData2.forEach((item, index) => {
        const element = { value: item.id, text: item.nama, id_asset: item.id_asset, id_kategori_kendaraan: item.id_kategori_kendaraan }
        this.needData2.push(element);
      });

      // needData3
      const needData3 = JSON.parse(localStorage.getItem('needData3'));
      console.log(needData3)
      needData3.forEach((item, index) => {
        const element = { value: item.id, text: item.nama, jenis: item.jenis, nama_asset: item.nama_asset, id_kategori_kendaraan: item.id_kategori_kendaraan }
        this.needData3.push(element);
      });

      // needData4
      const needData4 = JSON.parse(localStorage.getItem('needData4')) || [];
      this.needData4 = needData4.map((item) => ({
        value: item.id,
        text: item.nama,
       
      }));

      const needData5 = JSON.parse(localStorage.getItem('needData5')) || [];
      console.log('ini need data 5', needData5)
      this.needData5 = needData5.map((item) => ({
        value: item.id,
        // text: item.nama,
        id_routes: item.id_routes,
        id_jenis_muatan: item.id_jenis_muatan,
        id_kategori_kendaraan: item.id_kategori_kendaraan,
        uang_jalan: item.uang_jalan,
        uang_jalan_status: item.uang_jalan_status,
        uang_jalan_tambahan: item.uang_jalan_tambahan,
        uang_jalan_tambahan_status: item.uang_jalan_tambahan_status,
        uang_tambahan: item.uang_tambahan,
        uang_tambahan_status: item.uang_tambahan_status,
        uang_langsir: item.uang_langsir,
        uang_langsir_status: item.uang_langsir_status,
        uang_tinggal: item.uang_tinggal,
        uang_tinggal_status: item.uang_tinggal_status,
        uang_bongkar: item.uang_bongkar,
        uang_bongkar_status: item.uang_bongkar_status,
        uang_premi: item.uang_premi,
        uang_premi_status: item.uang_premi_status,
        uang_makan: item.uang_makan,
        uang_makan_status: item.uang_makan_status,
        upah_borongan: item.upah_borongan,
        upah_borongan_status: item.upah_borongan_status,
        bonus_tonase: item.bonus_tonase,
        bonus_tonase_status: item.bonus_tonase_status,
        bonus_trip: item.bonus_trip,
        bonus_trip_status: item.bonus_trip_status,
       
      }));

      const approveData = JSON.parse(localStorage.getItem('approveData'));
      if (approveData != null) {

        this.currentData.id_asset = approveData.id_assets;
        this.currentData.id_driver = approveData.id_drivers;


        // const index = this.needData2.findIndex((record) => record.value === approveData.id_drivers);
        const indexAsset = this.needData3.findIndex((record) => record.value === approveData.id_assets);
        // const indexAsset = this.needData3.findIndex((record) => record.value === )
        // console.log(index)
        if (indexAsset > -1) {
          this.currentData.jenis_aset = this.needData3[indexAsset].jenis;
          this.currentData.kendaraan = this.needData3[indexAsset].nama_asset;
        }
      }

      

    },



    newTrip() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append("jenis", 'KHUSUS');

      //tanggal muat

      // formData.delete("tanggal_muat");
      // if (this.currentData.tanggal_muat != 'Invalid Date' && this.currentData.tanggal_muat != new Date()) {
      //   var formattedDate = this.currentData.tanggal_muat.toISOString().split('T')[0];
      //   formData.append("tanggal_muat", formattedDate);
      // } else {
      //   formData.append("tanggal_muat", null);
      // }

      // //tanggal bongkar
      // formData.delete("tanggal_bongkar");
      // if (this.currentData.tanggal_bongkar != 'Invalid Date' && this.currentData.tanggal_bongkar != new Date()) {
      //   var formattedDate = this.currentData.tanggal_bongkar.toISOString().split('T')[0];
      //   formData.append("tanggal_bongkar", formattedDate);
      // } else {
      //   formData.append("tanggal_bongkar", null);
      // }

      formData.delete("tanggal_muat");
      const dateMuat = new Date(this.currentData.tanggal_muat);
      const formattedDateMuat = dateMuat.getFullYear() + '-' +
          String(dateMuat.getMonth() + 1).padStart(2, '0') + '-' +
          String(dateMuat.getDate()).padStart(2, '0');

      formData.append("tanggal_muat", formattedDateMuat);

      formData.delete("tanggal_bongkar");
      const dateBongkar = new Date(this.currentData.tanggal_bongkar);
      const formattedDateBongkar = dateBongkar.getFullYear() + '-' +
          String(dateBongkar.getMonth() + 1).padStart(2, '0') + '-' +
          String(dateBongkar.getDate()).padStart(2, '0');

      formData.append("tanggal_bongkar", formattedDateBongkar);

      formData.delete("is_supervisor");
      if (this.currentData.is_supervisor == true) {
        formData.append('is_supervisor', 'AKTIF');
      } else {
        formData.append('is_supervisor', 'TIDAK');
      }


      // Remove fields with "TIDAK" status
      // For uang_jalan field
      formData.delete("uang_jalan");
      if (this.uang_jalan_status === "TIDAK") {
        // Empty the field
        formData.append("uang_jalan", "");
      } else {
        // Use the rupiah conversion
        formData.append("uang_jalan", this.validationRupiahOutput(this.currentData.uang_jalan));
      }

      // Example for uang_jalan_tambahan field
      formData.delete("uang_jalan_tambahan");
      if (this.uang_jalan_tambahan_status === "TIDAK") {
        formData.append("uang_jalan_tambahan", "");
      } else {
        formData.append("uang_jalan_tambahan", this.validationRupiahOutput(this.currentData.uang_jalan_tambahan));
      }

      // Repeat similar checks for other fields:
      formData.delete("uang_tinggal");
      if (this.uang_tinggal_status === "TIDAK") {
        formData.append("uang_tinggal", "");
      } else {
        formData.append("uang_tinggal", this.validationRupiahOutput(this.currentData.uang_tinggal));
      }

      formData.delete("uang_langsir");
      if (this.uang_langsir_status === "TIDAK") {
        formData.append("uang_langsir", "");
      } else {
        formData.append("uang_langsir", this.validationRupiahOutput(this.currentData.uang_langsir));
      }

      formData.delete("uang_tambahan");
      if (this.uang_tambahan_status === "TIDAK") {
        formData.append("uang_tambahan", "");
      } else {
        formData.append("uang_tambahan", this.validationRupiahOutput(this.currentData.uang_tambahan));
      }

      formData.delete("uang_premi");
      if (this.uang_premi_status === "TIDAK") {
        formData.append("uang_premi", "");
      } else {
        formData.append("uang_premi", this.validationRupiahOutput(this.currentData.uang_premi));
      }

      formData.delete("uang_bongkar");
      if (this.uang_bongkar_status === "TIDAK") {
        formData.append("uang_bongkar", "");
      } else {
        formData.append("uang_bongkar", this.validationRupiahOutput(this.currentData.uang_bongkar));
      }

      formData.delete("uang_makan");
      if (this.uang_makan_status === "TIDAK") {
        formData.append("uang_makan", "");
      } else {
        formData.append("uang_makan", this.validationRupiahOutput(this.currentData.uang_makan));
      }

      formData.delete("bonus_tonase");
      if (this.bonus_tonase_status === "TIDAK") {
        formData.append("bonus_tonase", "");
      } else {
        formData.append("bonus_tonase", this.validationRupiahOutput(this.currentData.bonus_tonase));
      }

      formData.delete("bonus_trip");
      if (this.bonus_trip_status === "TIDAK") {
        formData.append("bonus_trip", "");
      } else {
        formData.append("bonus_trip", this.validationRupiahOutput(this.currentData.bonus_trip));
      }

      formData.delete("upah_borongan");
      if (this.upah_borongan_status === "TIDAK") {
        formData.append("upah_borongan", "");
      } else {
        formData.append("upah_borongan", this.validationRupiahOutput(this.currentData.upah_borongan));
      }

      // formData.append("id_mapping_komponen", this.needData5.value)

      if (this.needData5.length > 0) {
        this.needData5.forEach((item, index) => {
          formData.append(`id_mapping_komponen`, item.value); 
        });
      }

      if (this.$refs.file.value != "") {
        formData.append("file", this.$refs.file.files[0]);
      }


      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      // CloseLoading();
      // return false;



      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'operationalinsert';
      } else {
        this.api = base_url + 'operationalinsert';
      }

      // formData.append("jenis", "KHUSUS");
      // console.log(this.api)

      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status == true) {
            AlertBottom(response.data.message);
            localStorage.removeItem("approveData");

             // Clear currentData fields
             const currentProject = this.currentData.id_project;

            // Clear currentData fields
            this.currentData = {
              // Reset the structure based on the initial format of currentData
              id_project: currentProject,
              lokasi_muat: this.currentData.lokasi_muat,
              lokasi_bongkar: this.currentData.lokasi_bongkar,
              jenis_muatan: this.currentData.jenis_muatan,
              route: this.currentData.route,
              nama_customer: this.currentData.nama_customer,
              sto_no: this.currentData.sto_no,
              do_no: this.currentData.do_no,
              spk_no: this.currentData.spk_no,
              po_no: this.currentData.po_no,
              toleransi_susut: this.currentData.toleransi_susut,
              so_qty: this.currentData.so_qty,
              so_date: this.currentData.so_date,
              tanggal_muat: this.currentData.tanggal_muat,
              tanggal_bongkar: this.currentData.tanggal_bongkar,
              is_supervisor: false,
              uang_jalan: '',
              uang_tinggal: '',
              langsir: '',
              tambahan: '',
              
            };



            // Clear file input
            this.$refs.file.value = "";

            // goPage("addtripkhusus");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    processValue(value) {
      // If the value is a string starting with "=" (Excel formula), return as-is.
      if (typeof value === "string" && value.trim().charAt(0) === "=") {
        return value;
      }
      // If the value is numeric (or a string representing a number), apply rupiahoutput.
      if (!isNaN(value)) {
        return rupiahoutput(value);
      }
      // Otherwise, return the value as-is.
      return value;
    },
  
    processField(fieldName, status) {
      
      formData.delete(fieldName);
      if (status === "TIDAK") {
        formData.append(fieldName, "");
      } else {
        formData.append(fieldName, this.processValue(this.currentData[fieldName]));
      }
    },

    validationRupiahOutput(value) {

      if (typeof value === 'string') {
         // If value is a string starting with "=" (Excel formula), return it as-is.
        if ( value.trim().startsWith('=')) {
          return value;
        }


        // Remove non-digit characters except minus and period
        // let numericValue = value.replace(/[^0-9.-]+/g, '').replace('-','');
        let numericValue = value.replace(/[^0-9.-]+/g, '');
        numericValue = numericValue.replace(/(?!^)-/g, '');

        
        numericValue = numericValue.replace(/\./g, '');
        
        // If it's a valid number after stripping, return as a number
        if (!isNaN(numericValue) && numericValue.trim() !== '') {
          return Number(numericValue); // Ensure it returns a numeric type
        }

         
      }else {
        return value
      }
    

      
   
      
    },




    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append("jenis", 'KHUSUS');

      

      formData.delete("tanggal_muat");
      const dateMuat = new Date(this.currentData.tanggal_muat);
      const formattedDateMuat = dateMuat.getFullYear() + '-' +
          String(dateMuat.getMonth() + 1).padStart(2, '0') + '-' +
          String(dateMuat.getDate()).padStart(2, '0');

      formData.append("tanggal_muat", formattedDateMuat);

      formData.delete("tanggal_bongkar");
      const dateBongkar = new Date(this.currentData.tanggal_bongkar);
      const formattedDateBongkar = dateBongkar.getFullYear() + '-' +
          String(dateBongkar.getMonth() + 1).padStart(2, '0') + '-' +
          String(dateBongkar.getDate()).padStart(2, '0');

      formData.append("tanggal_bongkar", formattedDateBongkar);

      

      formData.delete("is_supervisor");
      if (this.currentData.is_supervisor == true) {
        formData.append('is_supervisor', 'AKTIF');
      } else {
        formData.append('is_supervisor', 'TIDAK');
      }

      // Remove fields with "TIDAK" status
      // For uang_jalan field
      formData.delete("uang_jalan");
      formData.append("uang_jalan_status", this.uang_jalan_status);

      if (this.uang_jalan_status === "TIDAK") {
        // Empty the field
        formData.append("uang_jalan", "");
      } else {

        let uangJalanValue = this.validationRupiahOutput(this.currentData.uang_jalan);
  
        formData.append("uang_jalan", uangJalanValue);
        
        // formData.append("uang_jalan", this.validationRupiahOutput(this.currentData.uang_jalan));
      }

      // Example for uang_jalan_tambahan field
      formData.delete("uang_jalan_tambahan");
      formData.append("uang_jalan_tambahan_status", this.uang_jalan_tambahan_status);
      if (this.uang_jalan_tambahan_status === "TIDAK") {
        formData.append("uang_jalan_tambahan", "");
      } else {

        formData.append("uang_jalan_tambahan", this.validationRupiahOutput(this.currentData.uang_jalan_tambahan));
      }

      // Repeat similar checks for other fields:
      formData.delete("uang_tinggal");
      formData.append("uang_tinggal_status", this.uang_tinggal_status);
      if (this.uang_tinggal_status === "TIDAK") {
        formData.append("uang_tinggal", "");
      } else {
        formData.append("uang_tinggal", this.validationRupiahOutput(this.currentData.uang_tinggal));
      }

      formData.delete("uang_langsir");
      formData.append("uang_langsir_status", this.uang_langsir_status);

      if (this.uang_langsir_status === "TIDAK") {
        formData.append("uang_langsir", "");
      } else {
        formData.append("uang_langsir", this.validationRupiahOutput(this.currentData.uang_langsir));
      }

      formData.delete("uang_tambahan");
      formData.append("uang_tambahan_status", this.uang_tambahan_status);
      if (this.uang_tambahan_status === "TIDAK") {
        formData.append("uang_tambahan", "");
      } else {
        formData.append("uang_tambahan", this.validationRupiahOutput(this.currentData.uang_tambahan));
      }

      formData.delete("uang_premi");
      formData.append("uang_premi_status", this.uang_premi_status);
      if (this.uang_premi_status === "TIDAK") {
        formData.append("uang_premi", "");
      } else {
        formData.append("uang_premi", this.validationRupiahOutput(this.currentData.uang_premi));
      }

      formData.delete("uang_bongkar");
      formData.append("uang_bongkar_status", this.uang_bongkar_status);
      if (this.uang_bongkar_status === "TIDAK") {
        formData.append("uang_bongkar", "");
      } else {
        formData.append("uang_bongkar", this.validationRupiahOutput(this.currentData.uang_bongkar));
      }

      formData.delete("uang_makan");
      formData.append("uang_makan_status", this.uang_makan_status);
      if (this.uang_makan_status === "TIDAK") {
        formData.append("uang_makan", "");
      } else {
        formData.append("uang_makan", this.validationRupiahOutput(this.currentData.uang_makan));
      }

      formData.delete("bonus_tonase");
      formData.append("bonus_tonase_status", this.bonus_tonase_status);
      if (this.bonus_tonase_status === "TIDAK") {
        formData.append("bonus_tonase", "");
      } else {
        formData.append("bonus_tonase", this.validationRupiahOutput(this.currentData.bonus_tonase));
      }

      formData.delete("bonus_trip");
      formData.append("bonus_trip_status", this.bonus_trip_status);
      if (this.bonus_trip_status === "TIDAK") {
        formData.append("bonus_trip", "");
      } else {
        formData.append("bonus_trip", this.validationRupiahOutput(this.currentData.bonus_trip));
      }

      formData.delete("upah_borongan");
      formData.append("upah_borongan_status", this.upah_borongan_status);
      if (this.upah_borongan_status === "TIDAK") {
        formData.append("upah_borongan", "");
      } else {
        formData.append("upah_borongan", this.validationRupiahOutput(this.currentData.upah_borongan));
      }


      if (this.$refs.file.value != "") {
        formData.append("file", this.$refs.file.files[0]);
      }


      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      // CloseLoading();
      // return false;



      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'operationalinsert';
      } else {
        this.api = base_url + 'operationalinsert';
      }

      // formData.append("jenis", "KHUSUS");
      // console.log(this.api)

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            localStorage.removeItem("approveData");

            goPage("tripkhusus");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>