<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>No Polisi<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.no_pol" placeholder="" required />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nama Supir <span class="text-danger">*</span></label>
                    <div class="select-split">
                      <div class="select-group w-100">
                        <model-select :options="needData1" placeholder="Pilih Supir" v-model="currentData.id_driver"
                          style="z-index: 501;">
                        </model-select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Kategori Kendaraan <span class="text-danger">*</span></label>
                    <div class="select-split">
                      <div class="select-group w-100">
                        <model-select :options="needData2" placeholder="Pilih Kategori"
                          v-model="currentData.id_kategori_aset" style="z-index: 501;">
                        </model-select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nama Kendaraan<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.nama" placeholder="" required />
                  </div>
                </div>

                
                

                
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal Kadaluarsa STNK </label>
                    <div class="input-groupicon" style="z-index: 800;">
                      <datepicker class="picker" :editable="true" :clearable="false" v-model="currentData.exp_stnk" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Uji Kir</label>
                    <input type="text" v-model="currentData.uji_kir" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal Kadaluarsa KIR </label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker class="picker" :editable="true" :clearable="false" v-model="currentData.exp_kir" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Imei</label>
                    <input type="text" v-model="currentData.imei" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>No GSM</label>
                    <input type="text" v-model="currentData.no_gsm" placeholder="" />
                  </div>
                </div>

                <pageheader title="Ban Depan" title1="" v-if="ban_depan_array.length > 0" />

                <div v-for="(item, index) in ban_depan_array" :key="item.id" class="row">
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Jenis Ban {{ index + 1 }}</label>
                      <div class="select-split">
                        <div class="select-group w-100">
                          <model-select :options="jenisBanList" placeholder="Pilih Jenis Ban" v-model="item.jenis_ban"
                            :style="{ zIndex: 600 - index }">
                          </model-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Merek Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.merk_ban" placeholder=""
                        @input="preventComma('ban_depan', index, 'merk_ban')" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Ukuran Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.ukuran_ban" placeholder=""
                        @input="preventComma('ban_depan', index, 'ukuran_ban')" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>No Seri Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.no_seri_ban" placeholder=""
                        @input="preventComma('ban_depan', index, 'no_seri_ban')" />
                    </div>
                  </div>
                </div>

                <pageheader title="Ban Belakang" title1="" v-if="ban_belakang_array.length > 0" />

                <div v-for="(item, index) in ban_belakang_array" :key="item.id" class="row">
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Jenis Ban {{ index + 1 }}</label>
                      <div class="select-split">
                        <div class="select-group w-100">
                          <model-select :options="jenisBanList" placeholder="Pilih Jenis Ban" v-model="item.jenis_ban"
                            :style="{ zIndex: 500 - index }">
                          </model-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Merek Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.merk_ban" placeholder=""
                        @input="preventComma('ban_belakang', index, 'merk_ban')" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Ukuran Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.ukuran_ban" placeholder=""
                        @input="preventComma('ban_belakang', index, 'ukuran_ban')" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>No Seri Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.no_seri_ban" placeholder=""
                        @input="preventComma('ban_belakang', index, 'no_seri_ban')" />
                    </div>
                  </div>
                </div>

                <pageheader title="Ban Serap" title1="" v-if="ban_serap_array.length > 0" />

                <div v-for="(item, index) in ban_serap_array" :key="item.id" class="row">
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Jenis Ban {{ index + 1 }}</label>
                      <div class="select-split">
                        <div class="select-group w-100">
                          <model-select :options="jenisBanList" placeholder="Pilih Jenis Ban" v-model="item.jenis_ban"
                            :style="{ zIndex: 400 - index }">
                          </model-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Merek Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.merk_ban" placeholder=""
                        @input="preventComma('ban_serap', index, 'merk_ban')" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Ukuran Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.ukuran_ban" placeholder=""
                        @input="preventComma('ban_serap', index, 'ukuran_ban')" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>No Seri Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.no_seri_ban" placeholder=""
                        @input="preventComma('ban_serap', index, 'no_seri_ban')" />
                    </div>
                  </div>
                </div>


                <pageheader title="List Perlengkapan" title1="" />

                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_dongkrak > 0
                    }" value="Dongkrak" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_dongkrak"
                      @input="checkStatus(currentData.qty_dongkrak, 'status_dongkrak')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_dongkrak" placeholder="" />
                  </div> -->
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_stik_dongkrak > 0
                    }" value="Stik Dongkrak" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_stik_dongkrak"
                      @input="checkStatus(currentData.qty_stik_dongkrak, 'status_stik_dongkrak')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_stik_dongkrak" placeholder="" />
                  </div> -->
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_ban_serap > 0
                    }" value="Ban Serap" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_ban_serap"
                      @input="checkStatus(currentData.qty_ban_serap, 'status_ban_serap')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_ban_serap" placeholder="" />
                  </div> -->
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_aki_2_unit > 0
                    }" value="Aki 2 Unit" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_aki_2_unit"
                      @input="checkStatus(currentData.qty_aki_2_unit, 'status_aki_2_unit')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_aki_2_unit" placeholder="" />
                  </div> -->
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_apd > 0
                    }" value="APD" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_apd"
                      @input="checkStatus(currentData.qty_apd, 'status_apd')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_apd" placeholder="" />
                  </div> -->
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_kotak_p3k > 0
                    }" value="Kotak P3K" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_kotak_p3k"
                      @input="checkStatus(currentData.qty_kotak_p3k, 'status_kotak_p3k')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_kotak_p3k" placeholder="" />
                  </div> -->
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_lasing > 0
                    }" value="Lasing" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_lasing"
                      @input="checkStatus(currentData.qty_lasing, 'status_lasing')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_lasing" placeholder="" />
                  </div> -->
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_seatbelt > 0
                    }" value="Seat Belt" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_seatbelt"
                      @input="checkStatus(currentData.qty_seatbelt, 'status_seatbelt')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_lasing" placeholder="" />
                  </div> -->
                </div>
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <router-link to="asset" class="btn btn-cancel">Batal</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>


<style scoped>
.bg-active {
  background-color: #043249 !important;
  color: white !important;
}
</style>


<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";
import { ModelSelect } from 'vue-search-select';
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {
    ModelSelect,
  },
  name: "editbank",
  data() {
    return {
      filter: true,
      title: "Perbaharui Kendaraan",
      title1: "perbaharui data kendaraan",
      config: "",
      api: "",
      foto_ktp_img: "",
      googleMap: "",

      //data
      currentData: {
      },

      jenisBanList: [
        {
          value: "Ban Mentah",
          text: "Ban Mentah"
        },
        {
          value: "Ban Masak",
          text: "Ban Masak"
        },

        {
          value: "Ban Benang",
          text: "Ban Benang"
        },
        {
          value: "Ban Kawat",
          text: "Ban Kawat"
        },

      ],


      needData1: [],
      needData2: [],

      ban_depan_array: [],
      ban_belakang_array: [],
      ban_serap_array: [],
      currentDate: new Date,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    //get data
    this.currentData = JSON.parse(localStorage.getItem('currentData'));
    this.currentData.exp_stnk = new Date(this.currentData.exp_stnk);
    this.currentData.exp_kir = new Date(this.currentData.exp_kir);
    this.prepareData();
    this.checkStatus(this.currentData.qty_dongkrak, 'status_dongkrak');
    this.checkStatus(this.currentData.qty_stik_dongkrak, 'status_stik_dongkrak');
    this.checkStatus(this.currentData.qty_ban_serap, 'status_ban_serap');
    this.checkStatus(this.currentData.qty_aki_2_unit, 'status_aki_2_unit');
    this.checkStatus(this.currentData.qty_apd, 'status_apd');
    this.checkStatus(this.currentData.qty_kotak_p3k, 'status_kotak_p3k');
    this.checkStatus(this.currentData.qty_lasing, 'status_lasing');
    this.checkKategoriAset(this.currentData.id_kategori_aset);


    let no_seri_ban_depan = this.splitAndCheckEmpty(this.currentData.no_seri_ban_depan);
    let jenis_ban_depan = this.splitAndCheckEmpty(this.currentData.jenis_ban_depan);
    let merk_ban_depan = this.splitAndCheckEmpty(this.currentData.merk_ban_depan);
    let ukuran_ban_depan = this.splitAndCheckEmpty(this.currentData.ukuran_ban_depan);

    let no_seri_ban_belakang = this.splitAndCheckEmpty(this.currentData.no_seri_ban_belakang);
    let jenis_ban_belakang = this.splitAndCheckEmpty(this.currentData.jenis_ban_belakang);
    let merk_ban_belakang = this.splitAndCheckEmpty(this.currentData.merk_ban_belakang);
    let ukuran_ban_belakang = this.splitAndCheckEmpty(this.currentData.ukuran_ban_belakang);

    let no_seri_ban_serap = this.splitAndCheckEmpty(this.currentData.no_seri_ban_serap);
    let jenis_ban_serap = this.splitAndCheckEmpty(this.currentData.jenis_ban_serap);
    let merk_ban_serap = this.splitAndCheckEmpty(this.currentData.merk_ban_serap);
    let ukuran_ban_serap = this.splitAndCheckEmpty(this.currentData.ukuran_ban_serap);

    if (no_seri_ban_depan.length > 0) {
      no_seri_ban_depan.forEach((item, index) => {
        this.ban_depan_array[index].no_seri_ban = item;
      });
    }


    if (jenis_ban_depan.length > 0) {
      jenis_ban_depan.forEach((item, index) => {
        this.ban_depan_array[index].jenis_ban = item;
      });
    }

    if (merk_ban_depan.length > 0) {
      merk_ban_depan.forEach((item, index) => {
        this.ban_depan_array[index].merk_ban = item;
      });
    }

    if (ukuran_ban_depan.length > 0) {
      ukuran_ban_depan.forEach((item, index) => {
        this.ban_depan_array[index].ukuran_ban = item;
      });
    }

    if (no_seri_ban_belakang.length > 0) {
      no_seri_ban_belakang.forEach((item, index) => {
        this.ban_belakang_array[index].no_seri_ban = item;
      });
    }

    if (jenis_ban_belakang.length > 0) {
      jenis_ban_belakang.forEach((item, index) => {
        this.ban_belakang_array[index].jenis_ban = item;
      });
    }

    if (merk_ban_belakang.length > 0) {
      merk_ban_belakang.forEach((item, index) => {
        this.ban_belakang_array[index].merk_ban = item;
      });
    }

    if (ukuran_ban_belakang.length > 0) {
      ukuran_ban_belakang.forEach((item, index) => {
        this.ban_belakang_array[index].ukuran_ban = item;
      });
    }


    if (no_seri_ban_serap.length > 0) {
      no_seri_ban_serap.forEach((item, index) => {
        this.ban_serap_array[index].no_seri_ban = item;
      });
    }

    if (jenis_ban_serap.length > 0) {
      jenis_ban_serap.forEach((item, index) => {
        this.ban_serap_array[index].jenis_ban = item;
      });
    }

    if (merk_ban_serap.length > 0) {
      merk_ban_serap.forEach((item, index) => {
        this.ban_serap_array[index].merk_ban = item;
      });
    }

    if (ukuran_ban_serap.length > 0) {
      ukuran_ban_serap.forEach((item, index) => {
        this.ban_serap_array[index].ukuran_ban = item;
      });
    }


  },

  watch: {
    'currentData.id_kategori_aset': {
      handler(newValue) {
        this.checkKategoriAset(newValue);
      }
    },
  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },

  methods: {
    splitAndCheckEmpty(input) {
      const result = input.split(', ');
      return result[0] === '' ? [] : result;
    },

    preventComma(jenis, index, field) {
      if (jenis == 'ban_depan') {
        this.ban_depan_array[index][field] = this.ban_depan_array[index][field].replace(/,/g, "");
      } else if (jenis == 'ban_belakang') {
        this.ban_belakang_array[index][field] = this.ban_belakang_array[index][field].replace(/,/g, "");
      } else {
        this.ban_serap_array[index][field] = this.ban_serap_array[index][field].replace(/,/g, "");
      }

    },
    checkKategoriAset(id) {
      const index = this.needData2.findIndex((record) => record.value === id);
      let jenis_ban_default = '';
      let ukuran_ban_default = '';
      if (this.needData2[index].status_ban_depan_belakang_sama == 'AKTIF') {
        jenis_ban_default = 'Ban Benang';
        ukuran_ban_default = this.needData2[index].ukuran_ban;
      } else {
        jenis_ban_default = '';
        ukuran_ban_default = this.needData2[index].ukuran_ban;
      }





      if (index > -1) {
        const jumlah_ban_depan = this.needData2[index].jumlah_ban_depan;
        const jumlah_ban_depan_seharusnya_1 = jumlah_ban_depan - this.ban_depan_array.length;
        const jumlah_ban_depan_seharusnya_2 = this.ban_depan_array.length - jumlah_ban_depan;
        if (jumlah_ban_depan == this.ban_depan_array.length) {

        } else if (jumlah_ban_depan == 0) {
          this.ban_depan_array = [];

        }
        else if (jumlah_ban_depan > this.ban_depan_array.length && this.ban_depan_array.length != 0) {
          for (var i = 0; i < jumlah_ban_depan_seharusnya_1; i++) {
            this.ban_depan_array.push({ id: i, no_seri_ban: '', ukuran_ban: this.needData2[index].ukuran_ban, jenis_ban: jenis_ban_default, merk_ban: '' });
          }
        } else if (jumlah_ban_depan < this.ban_depan_array.length && this.ban_depan_array.length != 0) {
          for (var i = 0; i < jumlah_ban_depan_seharusnya_2; i++) {
            this.ban_depan_array.pop();
          }
        } else {
          for (var i = 1; i <= jumlah_ban_depan; i++) {
            this.ban_depan_array.push({ id: i, no_seri_ban: '', ukuran_ban: this.needData2[index].ukuran_ban, jenis_ban: jenis_ban_default, merk_ban: '' });

          }
        }

        const jumlah_ban_belakang = this.needData2[index].jumlah_ban_belakang;

        const jumlah_ban_belakang_seharusnya_1 = jumlah_ban_belakang - this.ban_belakang_array.length;
        const jumlah_ban_belakang_seharusnya_2 = this.ban_belakang_array.length - jumlah_ban_belakang;
        if (jumlah_ban_belakang == this.ban_belakang_array.length) {

        } else if (jumlah_ban_belakang == 0) {
          this.ban_belakang_array = [];
        }
        else if (jumlah_ban_belakang > this.ban_belakang_array.length && this.ban_belakang_array.length != 0) {
          for (var i = 0; i < jumlah_ban_belakang_seharusnya_1; i++) {

            this.ban_belakang_array.push({ id: i, no_seri_ban: '', ukuran_ban: this.needData2[index].ukuran_ban, jenis_ban: jenis_ban_default, merk_ban: '' });

          }
        } else if (jumlah_ban_belakang < this.ban_belakang_array.length && this.ban_belakang_array.length != 0) {
          for (var i = 0; i < jumlah_ban_belakang_seharusnya_2; i++) {
            this.ban_belakang_array.pop();
          }
        } else {
          for (var i = 1; i <= jumlah_ban_belakang; i++) {
            this.ban_belakang_array.push({ id: i, no_seri_ban: '', ukuran_ban: this.needData2[index].ukuran_ban, jenis_ban: jenis_ban_default, merk_ban: '' });

          }
        }

        const jumlah_ban_serap = this.needData2[index].jumlah_ban_serap;
        const jumlah_ban_serap_seharusnya_1 = jumlah_ban_serap - this.ban_serap_array.length;
        const jumlah_ban_serap_seharusnya_2 = this.ban_serap_array.length - jumlah_ban_serap;

        if (jumlah_ban_serap == this.ban_serap_array.length) {

        } else if (jumlah_ban_serap == 0) {
          this.ban_serap_array = [];
        }
        else if (jumlah_ban_serap > this.ban_serap_array.length && this.ban_serap_array.length != 0) {
          for (var i = 0; i < jumlah_ban_serap_seharusnya_1; i++) {
            this.ban_serap_array.push({ id: i, no_seri_ban: '', ukuran_ban: this.needData2[index].ukuran_ban, jenis_ban: jenis_ban_default, merk_ban: '' });

          }
        } else if (jumlah_ban_serap < this.ban_serap_array.length && this.ban_serap_array.length != 0) {
          for (var i = 0; i < jumlah_ban_serap_seharusnya_2; i++) {
            this.ban_serap_array.pop();
          }
        }
        else {
          for (var i = 1; i <= jumlah_ban_serap; i++) {
            this.ban_serap_array.push({ id: i, no_seri_ban: '', ukuran_ban: this.needData2[index].ukuran_ban, jenis_ban: jenis_ban_default, merk_ban: '' });

          }
        }

        if (this.needData2[index].status_ban_depan_belakang_sama == 'AKTIF') {
          this.ban_depan_array.forEach((item, index) => {
            this.ban_depan_array[index].jenis_ban = jenis_ban_default;
            this.ban_depan_array[index].ukuran_ban = ukuran_ban_default;
          });

          this.ban_belakang_array.forEach((item, index) => {
            this.ban_belakang_array[index].jenis_ban = jenis_ban_default;
            this.ban_belakang_array[index].ukuran_ban = ukuran_ban_default;
          });

          this.ban_serap_array.forEach((item, index) => {
            this.ban_serap_array[index].jenis_ban = jenis_ban_default;
            this.ban_serap_array[index].ukuran_ban = ukuran_ban_default;
          });
        } else {
          this.ban_depan_array.forEach((item, index) => {
            this.ban_depan_array[index].ukuran_ban = ukuran_ban_default;
          });

          this.ban_belakang_array.forEach((item, index) => {
            this.ban_belakang_array[index].ukuran_ban = ukuran_ban_default;
          });

          this.ban_serap_array.forEach((item, index) => {
            this.ban_serap_array[index].ukuran_ban = ukuran_ban_default;
          });
        }

      }
    },
    checkStatus(qty, field) {
      if (qty > 0) {
        this.currentData[field] = true;
      } else {
        this.currentData[field] = false;
      }
    },
    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'assetsupdate';
      } else {
        this.api = base_url + 'assetsupdate';
      }

      formData.delete("exp_stnk");

      if (this.currentData.exp_stnk != undefined && this.currentData.exp_stnk != 'Invalid Date' && this.currentData.exp_stnk != new Date()) {
        // var formattedDate = this.currentData.exp_stnk.toISOString().split('T')[0];
        const date = this.currentData.exp_stnk;
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`; // YYYY-MM-DD format

        formData.append("exp_stnk", formattedDate);
      } else {
        formData.append("exp_stnk", null);
      }

      formData.delete("exp_kir");

      if (this.currentData.exp_kir != undefined && this.currentData.exp_kir != 'Invalid Date' && this.currentData.exp_kir != new Date()) {
        // var formattedDate = this.currentData.exp_kir.toISOString().split('T')[0];
        const date = this.currentData.exp_kir;
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`; 
        formData.append("exp_kir", formattedDate);
      } else {
        formData.append("exp_kir", null);
      }


      const ban_depan_array = this.ban_depan_array.map(item => item.no_seri_ban);
      formData.delete("no_seri_ban_depan");
      formData.append("no_seri_ban_depan", ban_depan_array.join(', '));

      const jenis_ban_depan_array = this.ban_depan_array.map(item => item.jenis_ban);
      formData.delete("jenis_ban_depan");
      formData.append("jenis_ban_depan", jenis_ban_depan_array.join(', '));

      const ukuran_ban_depan_array = this.ban_depan_array.map(item => item.ukuran_ban);
      formData.delete("ukuran_ban_depan");
      formData.append("ukuran_ban_depan", ukuran_ban_depan_array.join(', '));

      const merk_ban_depan_array = this.ban_depan_array.map(item => item.merk_ban);
      formData.delete("merk_ban_depan");
      formData.append("merk_ban_depan", merk_ban_depan_array.join(', '));

      const ban_belakang_array = this.ban_belakang_array.map(item => item.no_seri_ban);
      formData.delete("no_seri_ban_belakang");
      formData.append("no_seri_ban_belakang", ban_belakang_array.join(', '));

      const jenis_ban_belakang_array = this.ban_belakang_array.map(item => item.jenis_ban);
      formData.delete("jenis_ban_belakang");
      formData.append("jenis_ban_belakang", jenis_ban_belakang_array.join(', '));

      const ukuran_ban_belakang_array = this.ban_belakang_array.map(item => item.ukuran_ban);
      formData.delete("ukuran_ban_belakang");
      formData.append("ukuran_ban_belakang", ukuran_ban_belakang_array.join(', '));

      const merk_ban_belakang_array = this.ban_belakang_array.map(item => item.merk_ban);
      formData.delete("merk_ban_belakang");
      formData.append("merk_ban_belakang", merk_ban_belakang_array.join(', '));

      const ban_serap_array = this.ban_serap_array.map(item => item.no_seri_ban);
      formData.delete("no_seri_ban_serap");
      formData.append("no_seri_ban_serap", ban_serap_array.join(', '));

      const jenis_ban_serap_array = this.ban_serap_array.map(item => item.jenis_ban);
      formData.delete("jenis_ban_serap");
      formData.append("jenis_ban_serap", jenis_ban_serap_array.join(', '));

      const ukuran_ban_serap_array = this.ban_serap_array.map(item => item.ukuran_ban);
      formData.delete("ukuran_ban_serap");
      formData.append("ukuran_ban_serap", ukuran_ban_serap_array.join(', '));

      const merk_ban_serap_array = this.ban_serap_array.map(item => item.merk_ban);
      formData.delete("merk_ban_serap");
      formData.append("merk_ban_serap", merk_ban_serap_array.join(', '));

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("asset");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    prepareData() {

      // needData1
      const needData1 = JSON.parse(localStorage.getItem('needData1'));
      needData1.forEach((item, index) => {
        const element = { value: item.id, text: item.nama }
        this.needData1.push(element);
      });

      // needData2
      const needData2 = JSON.parse(localStorage.getItem('needData2'));

      needData2.forEach((item, index) => {
        const element = { value: item.id, text: item.nama, jumlah_ban_depan: item.jumlah_ban_depan, jumlah_ban_belakang: item.jumlah_ban_belakang, jumlah_ban_serap: item.jumlah_ban_serap, ukuran_ban: item.ukuran_ban, status_ban_depan_belakang_sama: item.status_ban_depan_belakang_sama }
        this.needData2.push(element);
      });
    }

  },

};
</script>
