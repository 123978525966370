<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">

              <div class="row">
                <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Jenis Muatan <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.nama" placeholder="" required />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Jenis Trip<span class="text-danger">*</span></label>
                    <vue-select :options="jenisList" v-model="currentData.jenis" placeholder="None" required />
                  </div>
                </div>
                
                
                <!-- UNHIDE IF NEEDED AGAIN -->
                <!-- <div v-for="item in menu_additional" :key="item.value" class="col-lg-3 col-sm-6 col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"  :checked="isChecked(item.component)" @change="toggleSelection(item.component)"  :id="'checkbox_' + index">
                    <label :for="'checkbox_' + index" style="color: #212b36;">
                      {{ item.text }}
                    </label>
                  </div>
                </div> -->
              


                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="selectedAdditional" value="UANG_JALAN" id="uangJalan">
                    <label  for="uangJalan" style="color: #212b36;">
                      Uang Jalan
                    </label>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="selectedAdditional" value="UANG_TINGGAL" id="uangTinggal">
                    <label  for="uangTinggal" style="color: #212b36;">
                      Uang Tinggal
                    </label>
                  </div>
                </div> -->

                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="selectedAdditional" value="UANG_LANGSIR" id="uangLangsir">
                    <label  for="uangLangsir" style="color: #212b36;">
                      Uang Langsir
                    </label>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="selectedAdditional" value="UANG_TAMBAHAN" id="uangTambahan">
                    <label  for="uangTambahan" style="color: #212b36;">
                      Uang Tambahan
                    </label>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12 mt-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="selectedAdditional" value="UANG_BORONGAN" id="upahBorongan">
                    <label for="upahBorongan" style="color: #212b36;">
                      Upah Borongan
                    </label>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12 mt-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="selectedAdditional" value="BONUS_PREMI" id="bonusPremi">
                    <label for="bonusPremi" style="color: #212b36;">
                      Bonus Premi
                    </label>
                  </div>
                </div> -->
                

                <div class="col-lg-12 mt-5">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <router-link to="typeload" class="btn btn-cancel">Batal</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";
import { ModelSelect } from 'vue-search-select';
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {
    ModelSelect,
  },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Tambah Jenis Muatan",
      title1: "add jenis muatan baru",
      config: "",
      api: "",

      jenisList: ["REGULER", "KHUSUS"],
      //data
      currentData: {
        
      },

      menu_additional: [],
      selectedAdditional: [], // Array to store selected checkbox values

    };
  },



  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    // this.prepareData()
  },
  
  methods: {

    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },

     // Check if the checkbox should be selected
    isChecked(value) {
      return this.selectedAdditional.includes(value);
    },

    // Toggle selection on checkbox change
    toggleSelection(value) {
      const index = this.selectedAdditional.indexOf(value);
      if (index === -1) {
        // If not found, add it
        this.selectedAdditional.push(value);
      } else {
        // If found, remove it
        this.selectedAdditional.splice(index, 1);
      }
      // console.log("Updated selectedAdditional:", this.selectedAdditional);
    },

    prepareData() {
      const menu_additional = JSON.parse(localStorage.getItem('menu_additional'));
      // console.log('menu addon:', menu_additional)
      menu_additional.forEach((item, index) => {
        const element = {
          value: item.id, text: item.nama_text, component: item.nama_component
        }
        this.menu_additional.push(element);

      });
    },

    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

       // Append the 'additional' field as a comma-separated string
       // Convert selectedAdditional to a comma-separated string
      // const additionalString = this.selectedAdditional.length > 0 
      //   ? this.selectedAdditional.join(',') 
      //   : '';

      //   formData.append("additional", additionalString);
        
      // formData.append("additional", JSON.stringify(this.selectedAdditional.length > 0 ? this.selectedAdditional : []));
        
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      // CloseLoading();
      // return false;


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'jenismuataninsert';
      } else {
        this.api = base_url + 'jenismuataninsert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("typeload");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>
