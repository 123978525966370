import { router } from "../../router";

// import Swal from "sweetalert2";
import iziToast from "izitoast";
import axios from "axios";
import XLSX from "xlsx/dist/xlsx.full.min.js";
import Cookies from "js-cookie";

// let base = "";
// let base2 = "";

// if (process.env.NODE_ENV === "development") {
//   base = "https://s4-api.arthaskyline.com/";
//   base2 = "https://s4-api.arthaskyline.com/";
// } else {
//   base = "https://s4-api.arthaskyline.com/";
//   base2 = "https://s4-api.arthaskyline.com/";
// }

// export let base_url_header = base;
// export let base_url = base2;

export let formatDateToString = (dateString) => {
  if (dateString == undefined) {
    return "";
  }
  var date = new Date(dateString);

  // Use Intl.DateTimeFormat to format the date
  var formattedDate = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(date);

  return formattedDate;
};

export let base_url_wilayah_indonesia =
  "https://sandbox.arthaskyline.com/api-wilayah-indonesia-master/";

let base = "";
if (process.env.NODE_ENV === "development") {
  // base = "https://acs-api.arthaskyline.com/";
  base = "https://api.andalancahayasejahtera.com/";
  // base = "https://s3-api.jempolku.co.id";
} else {
  // base = "https://acs-api.arthaskyline.com/";
  base = "https://api.andalancahayasejahtera.com/";
  // base = "https://s3-api.jempolku.co.id";
}

export let base_url = base;
// export let base_url_2 = "https://s3-api.arthaskyline.com";

export let timeout = 60000;

export const goPage = (text) => {
  router.push("/" + text);
};

export const refreshPage = () => {
  router.go();
};

export const removeHtmlTags = (inputString) => {
  // Use a regular expression to remove HTML tags
  return inputString.replace(/<[^>]*>/g, "");
};

// export const beforePage = () => {
//   router.go(-1);
// };

// export const goPageNewTab = (text) => {
//   // Do something here
//   window.open(process.env.BASE_URL + text, "_blank");
// };

export const Capitalize = (string) => {
  string = string.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const logOut = () => {
  Cookies.set("token_refresh", "");
  goPage("");
};

export const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const openInNewTab = (text) => {
  // Do something here
  window.open(text, "_blank");
};

export const AlertPopup = (
  icon,
  message,
  submessage,
  timer,
  showConfirmButton
) => {
  if (message == "No Authorization" || submessage == "No Authorization") {
    // console.log(message);
    // console.log(submessage);
    Cookies.set("token", "");
    goPage("");
  } else {
    Swal.fire({
      icon: icon,
      title: message,
      text: submessage,
      timer: timer,
      showConfirmButton: showConfirmButton,
    });
  }
};

export const AlertBottom = (text) => {
  iziToast.success({
    timeout: 2000,
    title: text,
  });
};

export const AlertInfoTopRight = (text, message) => {
  iziToast.info({
    position: "topLeft",
    timeout: 5000,
    title: text,
    message: message,
  });
};

export const ShowLoading = () => {
  Swal.fire({
    title: "",
    html: `

    <div class="loading-dots ">
        <div class="dot bg-dot"></div>
        <div class="dot bg-dot"></div>
        <div class="dot bg-dot"></div>
        <div class="dot bg-dot"></div>
    </div>

<style>
.bg-dot{
  background-color:#043249!important,
}
.loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.dot {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin: 0 4px;
    animation: fallingDots 1.2s infinite ease-in-out;
    animation-delay: 0.2s;
    /* Added initial delay before animation starts */
    animation-fill-mode: both;
    /* Added to maintain final state after animation */
}

.dot:nth-child(1) {
    animation-delay: 0s;
    /* Reset delay for the first dot */
}

.dot:nth-child(2) {
    animation-delay: 0.1s;
}

.dot:nth-child(3) {
    animation-delay: 0.2s;
}

.dot:nth-child(4) {
    animation-delay: 0.3s;
}

@keyframes fallingDots {
    0% {
        transform: translateY(-20px);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(-20px);
    }
}
</style>
`, // add html attribute if you want or remove
    background: 'transparent',
    allowOutsideClick: false,
    showConfirmButton: false,
    // didOpen: () => {
    //   Swal.showLoading();
    // },
  });
};

export const CloseLoading = () => {
  Swal.close();
};

export const ErrorConnectionTimeOut = (error) => {
  if (error.code == "ECONNABORTED") {
    AlertPopup(
      "error",
      "The internet connection is not stable.",
      "Check your internet connection and please refresh or try again.",
      0,
      true
    );
  } else if (error.code == "ERR_NETWORK") {
    AlertPopup(
      "error",
      "The internet connection is not stable.",
      "Check your internet connection and please refresh or try again.",
      0,
      true
    );
  } else if (error.code == "ERR_BAD_REQUEST") {
    if (error.response.request != undefined) {
      if (error.response.request.status == 401) {
        Cookies.set("token", "");

        CloseLoading();
        goPage("");
      }
    }
  } else {
    AlertPopup(
      "error",
      "",
      "We apologize, an error has occurred.",
      1500,
      false
    );
    console.log(error);
  }
  console.log(error);
};

export const addNumberToObjects = (objArray) => {
  for (let i = 0; i < objArray.length; i++) {
    const newObj = { No: i + 1, ...objArray[i] };
    objArray[i] = newObj;
  }
  return objArray;
};

// //export import
export const EksportExcel = (url, nama_file, config) => {
  ShowLoading();

  let api = "";
  let formData = new FormData();
  formData.append("url", url + "_export");

  if (process.env.NODE_ENV === "development") {
    api = base_url + url + "_export";
  } else {
    api = base_url + url + "_export";
  }

  axios
    .post(api, formData, config)
    .then((response) => {
      if (response.data.status == true) {
        // console.log(response.data);
        response.data.data = addNumberToObjects(response.data.data);
        for (var i = 0; i < response.data.data.length; i++) {
          delete response.data.data[i]["id"];
          delete response.data.data[i]["created_at"];
          delete response.data.data[i]["updated_at"];
        }

        // Create a new workbook
        const wb = XLSX.utils.book_new();
        // Create a new worksheet
        const ws = XLSX.utils.json_to_sheet(response.data.data);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, nama_file);
        // Convert the workbook to an XLSX file and download it
        XLSX.writeFile(wb, nama_file + ".xlsx");
        CloseLoading();
      }

      if (response.data.status == false) {
        AlertPopup("error", "", response.data.message, 1500, false);
      }
    })
    .catch((error) => {
      ErrorConnectionTimeOut(error);
    });
};

export const generateExcel = (
  tanggal_mulai,
  tanggal_selesai,
  url,
  nama_file,
  config
) => {
  ShowLoading();

  let api = "";
  let formData = new FormData();

  formData.append("tanggal_mulai", tanggal_mulai);
  formData.append("tanggal_selesai", tanggal_selesai);

  if (process.env.NODE_ENV === "development") {
    api = base_url + url;
  } else {
    api = base_url + url;
  }

  axios
    .post(api, formData, config)
    .then((response) => {
      if (response.data.status == true) {
        response.data.data = addNumberToObjects(response.data.data);
        for (var i = 0; i < response.data.data.length; i++) {
          delete response.data.data[i]["id"];
          delete response.data.data[i]["created_at"];
          delete response.data.data[i]["updated_at"];
        }

        // Create a new workbook
        const wb = XLSX.utils.book_new();
        // Create a new worksheet
        const ws = XLSX.utils.json_to_sheet(response.data.data);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, nama_file);
        // Convert the workbook to an XLSX file and download it
        XLSX.writeFile(wb, nama_file + ".xlsx");
        CloseLoading();
      }

      if (response.data.status == false) {
        AlertPopup("error", "", response.data.message, 1500, false);
      }
    })
    .catch((error) => {
      ErrorConnectionTimeOut(error);
    });
};

export const EksportExcelData = (data, url, nama_file, config) => {
  ShowLoading();

  let api = "";
  let formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  if (process.env.NODE_ENV === "development") {
    api = base_url + url;
  } else {
    api = base_url + url;
  }

  console.log(api);

  axios
    .post(api, formData, config)
    .then((response) => {
      if (response.data.status == true) {

        response.data.data = addNumberToObjects(response.data.data);
        for (var i = 0; i < response.data.data.length; i++) {
          delete response.data.data[i]["id"];
          delete response.data.data[i]["created_at"];
          delete response.data.data[i]["updated_at"];
        }

        // Create a new workbook
        const wb = XLSX.utils.book_new();
        // Create a new worksheet
        const ws = XLSX.utils.json_to_sheet(response.data.data);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, nama_file);
        // Convert the workbook to an XLSX file and download it
        XLSX.writeFile(wb, nama_file + ".xlsx");
        CloseLoading();
      }

      if (response.data.status == false) {
        AlertPopup("error", "", response.data.message, 1500, false);
      }
    })
    .catch((error) => {
      ErrorConnectionTimeOut(error);
    });
};

export const ImportExcel = (file_excel, url, config) => {
  return new Promise((resolve, reject) => {
    //export to json
    const file = file_excel;

    // Create a new file reader
    const reader = new FileReader();

    // Add an event listener to the reader
    reader.addEventListener("load", () => {
      // Parse the XLSX data and convert it to a JSON object
      const data = new Uint8Array(reader.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert the worksheet to a JSON object
      let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      for (var i = 0; i < jsonData.length; i++) {
        jsonData[i].shift();
      }

      let api = "";
      let formData = new FormData();
      formData.append("url", url + "_import");
      formData.append("data", JSON.stringify(jsonData));

      if (process.env.NODE_ENV === "development") {
        api = base_url + url + "_import";
      } else {
        api = base_url + url + "_import";
      }

      axios
        .post(api, formData, config)
        .then((response) => {
          if (response.data.status == true) {
            resolve(response.data);
            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
            reject(response.data.message);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
          reject(error);
        });
    });

    // Read the selected file as an array buffer
    reader.readAsArrayBuffer(file);
  });
};

export const DateFormat = (dateStr) => {
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  if (dateStr !== undefined && dateStr != "" && dateStr != null) {
    // Split the date and time parts
    const [datePart, timePart] = dateStr.split("T");
    timePart;
    const [year, month, day] = datePart.split("-");
    const formattedDate = `${parseInt(day, 10)} ${months[parseInt(month, 10) - 1]
      } ${year}`;

    return formattedDate;
  } else {
    return "";
  }
};

// export const EksportExcelUrl = (url, nama_file, progress, config) => {
//   ShowLoading();

//   let api = "";
//   let formData = new FormData();
//   formData.append("url", url);
//   formData.append("progress", progress);

//   if (process.env.NODE_ENV === "development") {
//     api = base_url_header + url;
//   } else {
//     api = base_url_header + url;
//   }
//   for (var pair of formData.entries()) {
//     console.log(pair[0] + ", " + pair[1]);
//   }

//   axios
//     .post(api, formData, config)
//     .then((response) => {
//       if (response.data.status == true) {
//         response.data.data = addNumberToObjects(response.data.data);
//         for (var i = 0; i < response.data.data.length; i++) {
//           delete response.data.data[i]["id"];
//           delete response.data.data[i]["id_profiles"];
//           delete response.data.data[i]["id_users"];
//           delete response.data.data[i]["id_relawan"];
//           delete response.data.data[i]["kode_unik"];
//           delete response.data.data[i]["kabupaten"];
//           delete response.data.data[i]["lingkungan"];
//           delete response.data.data[i]["jumlah_tanggungan"];
//           delete response.data.data[i]["telepon_ortu"];
//           delete response.data.data[i]["whatsapp_ortu"];
//           delete response.data.data[i]["status_ortu"];
//           delete response.data.data[i]["npsn_sekolah"];
//           delete response.data.data[i]["tahun_lulus_sekolah"];
//           delete response.data.data[i]["prestasi1_sekolah"];
//           delete response.data.data[i]["prestasi2_sekolah"];
//           delete response.data.data[i]["nama_pt"];
//           delete response.data.data[i]["alamat_pt"];
//           delete response.data.data[i]["nim_pt"];
//           delete response.data.data[i]["alamat_rumah"];
//           delete response.data.data[i]["kepemilikan_rumah"];
//           delete response.data.data[i]["tahun_perolahan_rumah"];
//           delete response.data.data[i]["sumber_listrik_rumah"];
//           delete response.data.data[i]["luas_bangunan_rumah"];
//           delete response.data.data[i]["facebook"];
//           delete response.data.data[i]["instagram"];
//           delete response.data.data[i]["twitter"];
//           delete response.data.data[i]["tiktok"];
//           delete response.data.data[i]["youtube"];
//           delete response.data.data[i]["foto_kip"];
//           delete response.data.data[i]["foto_penghasilan_orang_tua"];
//           delete response.data.data[i]["foto_ktp_ayah"];
//           delete response.data.data[i]["foto_ktp_ibu"];
//           delete response.data.data[i]["foto_ktp_siswa"];
//           delete response.data.data[i]["foto_rekening_listrik"];
//           delete response.data.data[i]["foto_rekening_air"];
//           delete response.data.data[i]["foto_keluarga"];
//           delete response.data.data[i]["foto_ptn"];
//           delete response.data.data[i]["foto_rumah_depan"];
//           delete response.data.data[i]["foto_rumah_dalam"];
//           delete response.data.data[i]["foto_rumah_dapur"];
//           delete response.data.data[i]["foto_bersama"];
//           delete response.data.data[i]["nama_rekomendasi"];
//           delete response.data.data[i]["jabatan_rekomendasi"];
//           delete response.data.data[i]["pekerjaan_rekomendasi"];
//           delete response.data.data[i]["hp_rekomendasi"];
//           delete response.data.data[i]["alamat_rekomendasi"];
//           delete response.data.data[i]["tanda_tangan_rekomendasi"];
//           delete response.data.data[i]["tanda_tangan"];
//           delete response.data.data[i]["jam_wawancara"];
//           delete response.data.data[i]["catatan"];
//           delete response.data.data[i]["st_ck_kk"];
//           delete response.data.data[i]["st_ck_rapor"];
//           delete response.data.data[i]["st_ck_datadirirapor"];
//           delete response.data.data[i]["st_ck_kelas_xii_genap_ganjil"];
//           delete response.data.data[i]["st_ck_skl_skhun"];
//           delete response.data.data[i]["st_ck_ijizah"];
//           delete response.data.data[i]["created_at"];
//           delete response.data.data[i]["updated_at"];
//         }

//         // Create a new workbook
//         const wb = XLSX.utils.book_new();
//         // Create a new worksheet
//         const ws = XLSX.utils.json_to_sheet(response.data.data);
//         // Add the worksheet to the workbook
//         XLSX.utils.book_append_sheet(wb, ws, nama_file);
//         // Convert the workbook to an XLSX file and download it
//         XLSX.writeFile(wb, nama_file + ".xlsx");
//         CloseLoading();
//       }

//       if (response.data.status == false) {
//         AlertPopup("error", "", response.data.message, 1500, false);
//       }
//     })
//     .catch((error) => {
//       ErrorConnectionTimeOut(error);
//     });
// };

export const DateNow = () => {
  var today = new Date();
  var dd = today.getDate().toString().padStart(2, "0");
  var mm = (today.getMonth() + 1).toString().padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const dateTime = today;
  return dateTime;
};

export const HourNow = () => {
  var today = new Date();
  var hours = today.getHours().toString().padStart(2, "0");
  var minutes = today.getMinutes().toString().padStart(2, "0");
  var currentTime = hours + ":" + minutes;
  return currentTime;
};

export const max1Year = () => {
  const currentDate = new Date();
  // Set the maximum date as the end of next year (December 31)
  let maxDate = new Date(currentDate.getFullYear() + 1, 11, 31);
  var dd = maxDate.getDate().toString().padStart(2, "0");
  var mm = (maxDate.getMonth() + 1).toString().padStart(2, "0"); //January is 0!
  var yyyy = maxDate.getFullYear();
  maxDate = yyyy + "-" + mm + "-" + dd;
  const dateTime = maxDate;

  return dateTime;
};
export const min1Year = () => {
  const currentDate = new Date();
  // Set the minimum date as one year before (January 1)
  let minDate = new Date(currentDate.getFullYear() - 1, 0, 1);
  var dd = minDate.getDate().toString().padStart(2, "0");
  var mm = (minDate.getMonth() + 1).toString().padStart(2, "0"); //January is 0!
  var yyyy = minDate.getFullYear();
  minDate = yyyy + "-" + mm + "-" + dd;
  const dateTime = minDate;

  return dateTime;
};
// export const DayNow = () => {
//   let weekday = [
//     "Minggu",
//     "Senin",
//     "Selasa",
//     "Rabu",
//     "Kamis",
//     "Jum'at",
//     "Sabtu",
//   ][new Date().getDay()];
//   return weekday;
// };
// export const MonthNow = () => {
//   let weekday = [
//     "Januari",
//     "Februari",
//     "Maret",
//     "April",
//     "Mei",
//     "Juni",
//     "Juli",
//     "September",
//     "Oktober",
//     "November",
//     "Desember",
//   ][new Date().getMonth()];
//   return weekday;
// };

// export const JustDateNow = () => {
//   var today = new Date();
//   var dd = String(today.getDate()).padStart(2, "0");
//   return dd;
// };
export const JustYearNow = () => {
  var today = new Date();
  var year = today.getFullYear();
  return year;
};

// export const TimeNow = () => {
//   var today = new Date();
//   const time =
//     ("0" + today.getHours()).substr(-2) +
//     ":" +
//     ("0" + today.getMinutes()).substr(-2);

//   return time;
// };

export const moneyFormat = (amount) => {
  // Convert the number to a string and split it into integer and decimal parts
  const [integerPart, decimalPart] = amount.toString().split('.');
  
  // Format the integer part with a dot as a thousands separator
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
  // Return the formatted currency, adding the decimal part if it exists
  return decimalPart ? `${formattedInteger},${decimalPart}` : formattedInteger;
};

export const moneyInputFormat = (value) => {
  if (value == null) {
    return '';
  }

  value = value.toString();
  if (value.slice(-1) == ",") {
    value = value.slice(0, -3) + value.slice(-2);
    const unformattedValue = parseFloat(value.replace(/[^0-9]/g, ""));
    if (!isNaN(unformattedValue)) {
      return unformattedValue.toLocaleString("id-ID");
    }

    return value;
  }
  if (typeof value === "string") {
    const unformattedValue = parseFloat(value.replace(/[^0-9]/g, ""));
    if (!isNaN(unformattedValue)) {
      return unformattedValue.toLocaleString("id-ID");
    }
    return "0";
  }

  return value;
};

// export const rupiahInput = (value) => {
//   if (value == null) {
//     return '';
//   }

//   value = value.toString();
//   if (value.slice(-1) == ",") {
//     value = value.slice(0, -3) + value.slice(-2);
//     const unformattedValue = parseFloat(value.replace(/[^0-9]/g, ""));
//     if (!isNaN(unformattedValue)) {
//       return "Rp " + unformattedValue.toLocaleString("id-ID") + ",-";
//     }

//     return value;
//   }
//   if (typeof value === "string") {
//     const unformattedValue = parseFloat(value.replace(/[^0-9]/g, ""));
//     if (!isNaN(unformattedValue)) {
//       return "Rp " + unformattedValue.toLocaleString("id-ID") + ",-";
//     }
//     return "Rp 0,-";
//   }

//   return value;
// };

export const moneyOutputFormat = (value) => {
  return parseInt(value.replace(/[^\d]/g, ""));
};

export const rupiah = (number) => {
  return (
    new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    })
      .format(number)
      .slice(0, -2) + "-"
  );
};

export const rupiahInput = (value) => {
  if (value == null) {
    return '';
  }

  value = value.toString();
  if (value.slice(-1) == ",") {
    value = value.slice(0, -3) + value.slice(-2);
    const unformattedValue = parseFloat(value.replace(/[^0-9]/g, ""));
    if (!isNaN(unformattedValue)) {
      return "Rp " + unformattedValue.toLocaleString("id-ID") + ",-";
    }

    return value;
  }
  if (typeof value === "string") {
    const unformattedValue = parseFloat(value.replace(/[^0-9]/g, ""));
    if (!isNaN(unformattedValue)) {
      return "Rp " + unformattedValue.toLocaleString("id-ID") + ",-";
    }
    return "Rp 0,-";
  }

  return value;
};

export const rupiahoutput = (value) => {
  return parseInt(value.replace(/[^\d]/g, ""));
};

export const BooleanToNumber = (value) => {
  if (value == true) {
    value = 1;
  } else {
    value = 0;
  }
  return value;
};
export const NumberToBoolean = (value) => {
  if (value == 1) {
    value = true;
  } else {
    value = false;
  }
  return value;
};

// export const randomString = () => {
//   let length = 10;
//   let result = "";
//   const characters =
//     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   const charactersLength = characters.length;
//   let counter = 0;
//   while (counter < length) {
//     result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     counter += 1;
//   }
//   return result;
// };

// export const recentUrl = () => {
//   const urlString = window.location.href;
//   const cleanString = urlString.split("?")[0];
//   const url = new URL(cleanString);
//   url.pathname = "/";

//   let modifiedString = url.toString();
//   return modifiedString + "dashboard/";
// };

// export function CharOnly(event) {
//   const keyCode = event.keyCode;
//   // Allow backspace and space
//   if (keyCode === 8 || keyCode === 32) {
//     return;
//   }
//   // Prevent input of numbers and symbols
//   if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 186 && keyCode <= 222)) {
//     event.preventDefault();
//   }
// }
export function CharOnly(event) {
  const keyCode = event.keyCode;
  // Allow backspace and space
  if (keyCode === 8 || keyCode === 32) {
    return;
  }
  // Convert keyCode to the corresponding character
  const character = String.fromCharCode(keyCode);
  // Regular expression pattern to match allowed characters
  const pattern = /^[a-zA-Z,.'\s]*$/;
  // Check if the character matches the pattern
  if (!pattern.test(character)) {
    event.preventDefault();
  }
}

export function CharAndSymbolsOnly(event) {
  const keyCode = event.keyCode;
  // Allow backspace and space
  if (keyCode === 8 || keyCode === 32) {
    return;
  }
  // Convert keyCode to the corresponding character
  const character = String.fromCharCode(keyCode);
  // Regular expression pattern to match allowed characters and symbols
  const pattern = /^[a-zA-Z.'\s]*$/;
  // Check if the character matches the pattern
  if (!pattern.test(character)) {
    event.preventDefault();
  }
}

export function CharAndNumberOnly(event) {
  const keyCode = event.keyCode;
  // Allow backspace and space
  if (keyCode === 8 || keyCode === 32) {
    return;
  }
  // Prevent input of symbols
  if (keyCode >= 186 && keyCode <= 222) {
    event.preventDefault();
  }
}
export function NumberOnly(event) {
  const key = event.key;
  // Allow backspace and space
  if (key === "Backspace" || key === " ") {
    return;
  }
  // Prevent input of non-numeric characters
  if (!/^\d$/.test(key)) {
    event.preventDefault();
  }
}

export function NumberOnlyAndSymbol(event) {
  const keyCode = event.keyCode;
  // Allow backspace and space
  if (keyCode === 8 || keyCode === 32) {
    return;
  }
  // Prevent input of characters other than numbers, forward slash, and hyphen
  if (
    (keyCode < 48 || keyCode > 57) && // Not a number
    keyCode !== 191 && // Not a forward slash
    keyCode !== 189 // Not a hyphen
  ) {
    event.preventDefault();
  }
}

export function menu_akses_cek(url, akses) {
  const tipe_user = localStorage.getItem("tipe_user");
  if (tipe_user == "dosen" || tipe_user == "pegawai") {
    //akses
    const menu_akses = JSON.parse(localStorage.getItem("menu_akses"));
    const index = menu_akses.findIndex((record) => record.menu === url);
    const menu_akses_array = menu_akses[index].akses.split(",");

    //crate akses
    const index_create = menu_akses_array.indexOf(akses);
    if (index_create !== -1) {
      return true;
    } else {
      return false;
    }
  } else if (tipe_user == "admin") {
    return true;
  } else {
    return true;
  }
}

export function cekListString(targetString, substrings) {
  for (let i = 0; i < substrings.length; i++) {
    if (targetString.includes(substrings[i])) {
      return true;
    }
  }
  return false;
}

export const hariList = [
  { nama: "Senin" },
  { nama: "Selasa" },
  { nama: "Rabu" },
  { nama: "Kamis" },
  { nama: "Jumat" },
  { nama: "Sabtu" },
];

export const sarjanaList = [
  { nama: "Administrasi Bisnis", kode: "S.A.B." },
  { nama: "Administrasi Fiskal", kode: "S.Pn." },
  { nama: "Administrasi Negara", kode: "S.Adm." },
  { nama: "Administrasi Perkantoran", kode: "S.A.P." },
  { nama: "Ilmu Perpustakaan", kode: "S.I.P." },
  { nama: "Akuntansi", kode: "S.Ak." },
  { nama: "Ilmu Ekonomi", kode: "S.E." },
  { nama: "Manajemen", kode: "S.E." },
  { nama: "Farmasi", kode: "S.Farm." },
  { nama: "Ilmu Gizi", kode: "S.Gz." },
  { nama: "Kebidanan", kode: "S.Keb." },
  { nama: "Kedokteran", kode: "S.Ked." },
  { nama: "Kedokteran Gigi", kode: "S.K.G." },
  { nama: "Kedokteran Hewan", kode: "S.K.H." },
  { nama: "Keperawatan", kode: "S.Kep." },
  { nama: "Kesehatan Masyarakat", kode: "S.K.M." },
  { nama: "Psikologi", kode: "S.Psi." },
  { nama: "Desain Komunikasi Visual", kode: "S.Ds." },
  { nama: "Desain Produk", kode: "S.Ds." },
  { nama: "Seni Kriya", kode: "S.Sn." },
  { nama: "Seni Musik", kode: "S.Sn." },
  { nama: "Seni Rupa", kode: "S.Sn." },
  { nama: "Seni Tari", kode: "S.Sn." },
  { nama: "Arkeologi", kode: "S.Hum." },
  { nama: "Filsafat", kode: "S.Fil." },
  { nama: "Ilmu Sejarah", kode: "S.Hum." },
  { nama: "Pariwisata", kode: "S.Par." },
  { nama: "Sastra Arab", kode: "S.S." },
  { nama: "Sastra Indonesia", kode: "S.S." },
  { nama: "Sastra Inggris", kode: "S.S." },
  { nama: "Sastra Jawa", kode: "S.S." },
  { nama: "Sastra Jepang", kode: "S.S." },
  { nama: "Sastra Prancis", kode: "S.S." },
  { nama: "Astronomi", kode: "S.Si." },
  { nama: "Biologi", kode: "S.Si." },
  { nama: "Fisika", kode: "S.Si." },
  { nama: "Geofisika", kode: "S.Si." },
  { nama: "Geologi", kode: "S.Si." },
  { nama: "Kimia", kode: "S.Si." },
  { nama: "Matematika", kode: "S.Si." },
  { nama: "Statistika", kode: "S.Stat." },
  { nama: "Bimbingan Konseling", kode: "S.Pd." },
  { nama: "Kebijakan Pendidikan", kode: "S.Pd." },
  { nama: "Manajemen Pendidikan", kode: "S.Pd." },
  { nama: "Pendidikan Luar Biasa", kode: "S.Pd." },
  { nama: "Pendidikan Luar Sekolah", kode: "S.Pd." },
  { nama: "PGPAUD", kode: "S.Pd." },
  { nama: "PGSD", kode: "S.Pd." },
  { nama: "Teknologi Pendidikan", kode: "S.Pd." },
  { nama: "Agribisnis", kode: "S.P." },
  { nama: "Agroteknologi", kode: "S.P." },
  { nama: "Ilmu Tanah", kode: "S.P." },
  { nama: "Kehutanan", kode: "S.Hut." },
  { nama: "Perikanan", kode: "S.Pi." },
  { nama: "Teknologi Hasil Pertanian", kode: "S.T.P." },
  { nama: "Antropologi Sosial", kode: "S.Ant." },
  { nama: "Hubungan Internasional", kode: "S.H.Int." },
  { nama: "Hukum", kode: "S.H." },
  { nama: "Geografi", kode: "S.Si." },
  { nama: "Ilmu Kesejahteraan Sosial", kode: "S.Sos." },
  { nama: "Ilmu Komunikasi", kode: "S.I.Kom." },
  { nama: "Ilmu Politik", kode: "S.IP." },
  { nama: "Kriminologi", kode: "S.Sos." },
  { nama: "Sosiologi", kode: "S.Sos." },
  { nama: "Arsitektur", kode: "S.Ars." },
  { nama: "Ilmu Komputer", kode: "S.Kom." },
  { nama: "Sistem Informasi", kode: "S.Kom." },
  { nama: "Teknik Bioproses", kode: "S.T." },
  { nama: "Teknik Elektro", kode: "S.T." },
  { nama: "Teknik Elektronika", kode: "S.T." },
  { nama: "Teknik Fisika", kode: "S.T." },
  { nama: "Teknik Geodesi", kode: "S.T." },
  { nama: "Teknik Geofisika", kode: "S.T." },
  { nama: "Teknik Geologi", kode: "S.T." },
  { nama: "Teknik Industri", kode: "S.T." },
  { nama: "Teknik Informatika", kode: "S.Kom." },
  { nama: "Teknik Kelautan", kode: "S.T." },
  { nama: "Teknik Kimia", kode: "S.T." },
  { nama: "Teknik Telekomunikasi", kode: "S.T." },
  { nama: "Teknik Lingkungan", kode: "S.T." },
  { nama: "Teknik Metalurgi", kode: "S.T." },
  { nama: "Teknik Mekatronika", kode: "S.T." },
  { nama: "Teknik Mesin", kode: "S.T." },
  { nama: "Teknik Nuklir", kode: "S.T." },
  { nama: "Teknik Otomotif", kode: "S.T." },
  { nama: "Teknik Perminyakan", kode: "S.T." },
  { nama: "Teknik Pertambangan", kode: "S.T." },
  { nama: "Teknik Sipil", kode: "S.T." },
];

export const magisterList = [
  { nama: "Magister Administrasi Bisnis", kode: "M.A.B." },
  { nama: "Magister Administrasi Pendidikan", kode: "M.A.Pd." },
  { nama: "Magister Administrasi Publik", kode: "M.A.P." },
  { nama: "Magister Administrasi Rumah Sakit", kode: "M.A.R.S." },
  { nama: "Magister Agama", kode: "M.A./M.Ag." },
  { nama: "Magister Agama bidang Hukum", kode: "M.A.Hk." },
  { nama: "Magister Agama bidang Humaniora", kode: "M.A.Hum." },
  { nama: "Magister Agama bidang Kedokteran", kode: "M.A.Ked." },
  { nama: "Magister Agama bidang Pendidikan", kode: "M.A.Pd." },
  { nama: "Magister Agama bidang Sains", kode: "M.A.Si." },
  { nama: "Magister Agrikultur", kode: "M.Agr." },
  { nama: "Magister Akuntansi", kode: "M.Ak." },
  { nama: "Magister Arsitektur", kode: "M.Ars." },
  { nama: "Magister Artium", kode: "M.A." },
  { nama: "Magister Biomedik", kode: "M.Biomed" },
  { nama: "Magister Bioteknologi", kode: "M.Biotech" },
  { nama: "Magister Desain", kode: "M.Ds." },
  { nama: "Magister Divinitas", kode: "M.Div." },
  { nama: "Magister Ekonomi", kode: "M.E." },
  { nama: "Magister Ekonomi Islam", kode: "M.E.I." },
  { nama: "Magister Ekonomi Syariah", kode: "M.E.Sy." },
  { nama: "Magister Epidemiologi", kode: "M.Epid." },
  { nama: "Magister Farmasi", kode: "M.Farm." },
  { nama: "Magister Farmasi Klinik", kode: "M.Farm.Klin." },
  { nama: "Magister Filsafat", kode: "M.Fil." },
  { nama: "Magister Filsafat Islam", kode: "M.Fil.I." },
  { nama: "Magister Fisiologi Olahraga", kode: "M.Fis." },
  { nama: "Magister Hukum", kode: "M.H." },
  { nama: "Magister Hukum Islam", kode: "M.H.I." },
  { nama: "Magister Hukum Kesehatan", kode: "M.H.Kes." },
  { nama: "Magister Humaniora", kode: "M.Hum." },
  { nama: "Magister Ilmu Administrasi", kode: "M.A./M.I.A." },
  { nama: "Magister Ilmu Biomedik", kode: "M.Si.Med" },
  { nama: "Magister Ilmu Kepolisian", kode: "M.I.K." },
  { nama: "Magister Ilmu Kesejahteraan Sosial", kode: "M.Kesos." },
  { nama: "Magister Ilmu Komputer", kode: "M.Kom." },
  { nama: "Magister Ilmu Komunikasi", kode: "M.I.Kom." },
  { nama: "Magister Ilmu Lingkungan", kode: "MIL." },
  { nama: "Magister Ilmu Pertahanan", kode: "M.Han." },
  { nama: "Magister Ilmu Politik", kode: "M.I.P." },
  { nama: "Magister Ilmu Pemerintahan", kode: "M.IP." },
  { nama: "Magister Ilmu Syariah", kode: "M.Sy." },
  { nama: "Magister Ilmu Ushuludin", kode: "M.Ud." },
  { nama: "Magister Kebidanan", kode: "M.Keb." },
  { nama: "Magister Kedokteran Kerja", kode: "M.K.K." },
  { nama: "Magister Kedokteran Tropis", kode: "M.Ked.Trop." },
  { nama: "Magister Kehutanan", kode: "M.Hut." },
  { nama: "Magister Kenotariatan", kode: "M.Kn." },
  { nama: "Magister Keolahragaan", kode: "M.Or." },
  { nama: "Magister Keperawatan", kode: "M.Kep." },
  { nama: "Magister Kesehatan", kode: "M.Kes." },
  { nama: "Magister Kesehatan Masyarakat", kode: "M.K.M./M.Kes." },
  { nama: "Magister Keselamatan dan Kesehatan Kerja", kode: "M.K.K.K." },
  { nama: "Magister Komputer", kode: "M.Kom." },
  { nama: "Magister Linguistik", kode: "M.Li." },
  { nama: "Magister Manajemen", kode: "M.M." },
  { nama: "Magister Manajemen Agribisnis", kode: "M.M.A." },
  { nama: "Magister Manajemen Pariwisata", kode: "M.M.Par." },
  { nama: "Magister Manajemen Pendidikan", kode: "M.M.Pd./M.M." },
  { nama: "Magister Manajemen Rumah Sakit", kode: "M.M.R.S" },
  { nama: "Magister Terapan Keperawatan", kode: "M.Tr.Kep" },
  { nama: "Magister Manajemen Sistem Informasi", kode: "M.M.S.I." },
  { nama: "Magister Manajemen Teknik", kode: "M.M.T." },
  { nama: "Magister Marine", kode: "M.Mar." },
  { nama: "Magister Misiologi", kode: "M.Miss." },
  { nama: "Magister Pemikiran Islam", kode: "M.P.I." },
  { nama: "Magister Pendidikan", kode: "M.Pd." },
  { nama: "Magister Pendidikan Islam", kode: "M.Pd.I." },
  { nama: "Magister Pendidikan Sains", kode: "M.Pd.Si." },
  { nama: "Magister Pendidikan Fisika", kode: "M.Pd.Fis." },
  { nama: "Magister Pendidikan Kimia", kode: "M.Pd.Kim." },
  { nama: "Magister Pendidikan Matematika", kode: "M.Pd.Mat." },
  { nama: "Magister Pertanian", kode: "M.P." },
  { nama: "Magister Psikologi", kode: "M.Psi." },
  { nama: "Magister Perencanaan Wilayah dan Kota", kode: "M.PWK" },
  { nama: "Magister Rekayasa Infrastruktur", kode: "M.RI" },
  { nama: "Magister Sains", kode: "M.Si./M.S." },
  { nama: "Magister Sains Akuntansi", kode: "M.S.Ak." },
  { nama: "Magister Sains bidang Ilmu Pertahanan", kode: "M.Si.(Han)" },
  { nama: "Magister Sains Ekonomi", kode: "M.S.E." },
  { nama: "Magister Sains Manajemen", kode: "M.S.M." },
  { nama: "Magister Sastra", kode: "M.S." },
  { nama: "Magister Seni", kode: "M.Sn." },
  { nama: "Magister Sosial Islam", kode: "M.Sos.I." },
  { nama: "Magister Statistik", kode: "M.Stat." },
  { nama: "Magister Studi Islam", kode: "M.S.I." },
  { nama: "Magister Teknik", kode: "M.T." },
  { nama: "Magister Teknologi Agroindustri", kode: "M.T.A." },
  { nama: "Magister Teknologi Informasi", kode: "M.TI." },
  { nama: "Magister Teknologi Pertanian", kode: "M.T.P." },
  { nama: "Magister Divinitas", kode: "M.Div." },
  { nama: "Magister Teologi Islam", kode: "M.Th.I." },
  { nama: "Magister Ministri", kode: "M.Min." },
  { nama: "Magister Teologia", kode: "M.Th." },
  { nama: "Magister Terapan", kode: "M.Tr." },
  { nama: "Magister Terapan Operasi Laut", kode: "M.Tr.Opsla." },
  { nama: "Magister Terapan Pertahanan", kode: "M.Tr.(Han)." },
  { nama: "Magister Terapan Pertahanan Laut", kode: "M.Tr.Hanla." },
  { nama: "Magister Terapan Ilmu Pemerintahan", kode: "M.Tr.I.P." },
  { nama: "Master of Urban Regional Planning", kode: "M.URP" },
  { nama: "Magister Veteriner", kode: "M.Vet." },
  { nama: "Master of Accounting", kode: "M.Acc." },
  { nama: "Master of Advanced Midwifery Practice", kode: "MAMP" },
  { nama: "Master of Arts", kode: "M.A." },
  { nama: "Master of Arts Education", kode: "M.A.Ed." },
  { nama: "Master of Business Administration", kode: "M.B.A." },
  { nama: "Master of Computer", kode: "M.Com." },
  { nama: "Master of Computer Science", kode: "M.Cs." },
  { nama: "Master of Economic", kode: "M.Ec." },
  { nama: "Master of Education", kode: "M.Ed." },
  { nama: "Master of Engineering", kode: "M.Eng." },
  { nama: "Master of Fine Arts", kode: "M.F.A." },
  { nama: "Master of Health Administration", kode: "M.H.A." },
  { nama: "Master of International Business", kode: "M.I.B." },
  { nama: "Master of Laws", kode: "LL.M." },
  { nama: "Master of Library Science", kode: "M.L.S." },
  { nama: "Master of Music", kode: "M.M." },
  { nama: "Master of Philosophy", kode: "M.Phil." },
  { nama: "Master of Public Administration", kode: "M.P.A." },
  { nama: "Master of Public Health", kode: "M.P.H." },
  { nama: "Master of Public Policy", kode: "M.P.P." },
  { nama: "Master of Science", kode: "M.S." },
  { nama: "Master of Social Work", kode: "M.S.W." },
  { nama: "Master of Theological Studies", kode: "M.T.S." },
  { nama: "Master of Urban Planning", kode: "M.U.P." },
  { nama: "Master of Urban Studies", kode: "M.U.S." },
  { nama: "Master of Veterinary Science", kode: "M.V.S." },
];

export const golonganList = [
  { nama: "3B" },
  { nama: "3C" },
  { nama: "3D" },
  { nama: "4A" },
  { nama: "4B" },
  { nama: "4C" },
  { nama: "4D" },
  { nama: "4E" },
];

export const jenisKelaminList = [{ nama: "Pria" }, { nama: "Wanita" }];

export const agamaList = [
  {
    nama: "Islam",
  },
  {
    nama: "Katolik",
  },
  {
    nama: "Protestan",
  },
  {
    nama: "Hindu",
  },
  {
    nama: "Buddha",
  },
  {
    nama: "Konghucu",
  },
];

export const wargaNegaraList = [
  {
    nama: "WNI",
  },
  {
    nama: "WNA",
  },
];


