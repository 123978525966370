<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">

                <div class="col-lg-12 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nama <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.nama" placeholder="" required />
                  </div>
                </div>

                <!-- <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Jenis Muatan <span class="text-danger">*</span></label>
                    <model-select :options="needData1" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.id_jenis_muatan" class="custom-width form-control" placeholder=""></model-select>

                  </div>
                </div> -->


                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Lokasi Muat <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.lokasi_muat" placeholder="" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>GPS Lokasi Muat<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.latlong_muat" placeholder="" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Lokasi Bongkar<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.lokasi_bongkar" placeholder="" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>GPS Lokasi Bongkar<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.latlong_bongkar" placeholder="" required />
                  </div>
                </div>

                <!-- <div class="col-lg-3 col-sm-6 col-12" v-show="statusJalan">
                  <div class="form-group">
                    <label>Uang Jalan<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.uang_jalan" @input="formatCurrency('uang_jalan', currentData.uang_jalan)" placeholder="" required />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12" v-show="statusTinggal">
                  <div class="form-group">
                    <label>Uang Tinggal<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.uang_tinggal" @input="formatCurrency('uang_tinggal', currentData.uang_tinggal)" placeholder="" required />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12" v-show="statusLangsir">
                  <div class="form-group">
                    <label>Uang Langsir<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.uang_langsir" @input="formatCurrency('uang_langsir', currentData.uang_langsir)" placeholder="" required />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12" v-show="statusTambahan">

                  <div class="form-group">
                    <label>Uang Tambahan<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.uang_tambahan" @input="formatCurrency('uang_tambahan', currentData.uang_tambahan)" placeholder="" required />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12" v-show="statusBorongan">

                  <div class="form-group">
                    <label>Upah Borongan<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.uang_borongan" @input="formatCurrency('uang_borongan', currentData.uang_borongan)" placeholder=""  />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12" v-show="statusPremi">

                  <div class="form-group">
                    <label>Bonus Premi<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.bonus_premi" @input="formatCurrency('bonus_premi', currentData.bonus_premi)" placeholder=""  />
                  </div>
                </div> -->
                



                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <router-link to="route" class="btn btn-cancel">Batal</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, rupiahInput, rupiahoutput, rupiah } from "@/assets/js/function";
import { ModelSelect } from 'vue-search-select';
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {
    ModelSelect,
  },
  name: "editbank",
  data() {
    return {
      filter: true,
      title: "Perbaharui Rute",
      title1: "perbaharui data rute",
      config: "",
      api: "",
      foto_ktp_img: "",
      googleMap: "",

      //data
      currentData: {
        id_jenis_muatan: "", // Selected ID
        // uang_jalan: "",
        // uang_tinggal: "",
        // uang_langsir: "",
        // uang_tambahan: "",
        // uang_borongan: "",
        // bonus_premi: "",
      },

      needData1: [],

      // statusJalan: false,
      // statusTinggal: false,
      // statusLangsir: false,
      // statusTambahan: false,
      // statusBorongan: false,
      // statusPremi: false,

      currentDate: new Date,
    };
  },

  watch: {
    // "currentData.id_jenis_muatan"(newVal) {
    //   // console.log("ini triggernya:", newVal);
    //   // Find the selected item from needData1
    //   const selected = this.needData1.find((item) => item.value === newVal);
    //   console.log('ini selectnya:', selected)

    //   // Reset all statuses initially
    //   this.statusJalan = false;
    //   this.statusTinggal = false;
    //   this.statusLangsir = false;
    //   this.statusTambahan = false;
    //   this.statusBorongan = false;
    //   this.statusPremi = false;

    //   if (selected && selected.additional) {
    //     let additionalMuatan = [];

    //     // If additional is an array, use it directly; otherwise, split if it's a string
    //     if (Array.isArray(selected.additional)) {
    //       additionalMuatan = selected.additional;
    //     } else if (typeof selected.additional === "string") {
    //       additionalMuatan = selected.additional.split(",");
    //     }

    //     // .map(item => item.trim().toUpperCase())
    //     // additionalMuatan.forEach((item, index) => {
    //     //   const element = { value: item.id, text: item.nama }
    //     //   this.needData1.push(element);
    //     // });

    //     // console.log('ini adisi:')
    //     // console.log(additionalMuatan)
    //     this.statusJalan = additionalMuatan.map(item => item.trim()).includes('UANG_JALAN');
    //     this.statusTinggal = additionalMuatan.map(item => item.trim()).includes('UANG_TINGGAL');
    //     this.statusLangsir = additionalMuatan.map(item => item.trim()).includes('UANG_LANGSIR');
    //     this.statusTambahan = additionalMuatan.map(item => item.trim()).includes('UANG_TAMBAHAN');
    //     this.statusBorongan = additionalMuatan.map(item => item.trim()).includes('UANG_BORONGAN');
    //     this.statusPremi = additionalMuatan.map(item => item.trim()).includes('BONUS_PREMI');
    //     // Check and set the status for each field based on the additional field
    //     // this.statusJalan = additionalMuatan.includes("UANG_JALAN");
    //     // this.statusTinggal = additionalMuatan.includes("UANG_TINGGAL");
    //     // this.statusLangsir = additionalMuatan.includes("UANG_LANGSIR");
    //     // this.statusTambahan = additionalMuatan.includes("UANG_TAMBAHAN");
    //     // this.statusBorongan = additionalMuatan.includes("UANG_BORONGAN");
    //     // this.statusPremi = additionalMuatan.includes("BONUS_PREMI");

    //     // console.log( this.statusJalan, this.statusTinggal, this.statusLangsir, this.statusTambahan, this.statusBorongan, this.statusPremi)
    //   }

    //   // // Check if the `additional` field includes "UANG_TAMBAHAN"
    //   // if (selected && selected.additional.includes("UANG_TAMBAHAN")) {
        
    //   //   this.statusTambahan = true;

    //   // }else{
        
    //   //   this.status = false;
    //   // }
    // },
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    //get data
    this.currentData = JSON.parse(localStorage.getItem('currentData'));

    this.prepareData()


  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {
    formatCurrency(label, number) {
      this.currentData[label] = rupiahInput(number);
    },


    prepareData() {

      // const needData1 = JSON.parse(localStorage.getItem('needData1'));
      // this.needData1 = needData1.map((item) => ({
      //   value: item.id,
      //   text: item.nama,
      //   // additional: item.additional.split(",") // Convert additional string to an array
      // }));
      

      // const indexJenisMuatan = this.needData1.findIndex((record) => record.value == this.currentData.id_jenis_muatan);
      // if (indexJenisMuatan > -1) {
      //   this.currentData.id_jenis_muatan = this.needData1[indexJenisMuatan].value;
      // }

      // this.currentData.uang_jalan = rupiahInput(this.currentData.uang_jalan)
      // this.currentData.uang_tinggal = rupiahInput(this.currentData.uang_tinggal)
      // this.currentData.uang_langsir = rupiahInput(this.currentData.uang_langsir)
      // this.currentData.uang_tambahan = rupiahInput(this.currentData.uang_tambahan)
      // this.currentData.uang_borongan = rupiahInput(this.currentData.uang_borongan)
      // this.currentData.bonus_premi = rupiahInput(this.currentData.bonus_premi)


    },

    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // formData.delete("uang_jalan");
      // formData.append('uang_jalan', rupiahoutput(this.currentData.uang_jalan));
      // formData.delete("uang_tinggal");
      // formData.append('uang_tinggal', rupiahoutput(this.currentData.uang_tinggal));
      // formData.delete("uang_langsir");
      // formData.append('uang_langsir', rupiahoutput(this.currentData.uang_langsir));
      // formData.delete("uang_tambahan");
      // formData.append('uang_tambahan', rupiahoutput(this.currentData.uang_tambahan));
      // formData.delete("uang_borongan");
      // formData.append('uang_borongan', rupiahoutput(this.currentData.uang_borongan));
      // formData.delete("bonus_premi");
      // formData.append('bonus_premi', rupiahoutput(this.currentData.bonus_premi));

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      // CloseLoading();
      // return false;
      

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'routesupdate';
      } else {
        this.api = base_url + 'routesupdate';
      }


      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("route");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>
